
import { initializeApp } from "firebase/app";
import { getMessaging, getToken} from "firebase/messaging";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBM0NW0OtYrle2oS6lgnK7AqAccULjyAnQ",
  authDomain: "restmanage-autoconta-restauran.firebaseapp.com",
  databaseURL: "https://restmanage-autoconta-restauran-default-rtdb.firebaseio.com",
  projectId: "restmanage-autoconta-restauran",
  storageBucket: "restmanage-autoconta-restauran.appspot.com",
  messagingSenderId: "1011877490684",
  appId: "1:1011877490684:web:28ad7ba984887ad828cc79",
  measurementId: "G-VSPSKHPPEN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
//const analytics = getAnalytics(app);

export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);

    if (permission === "granted"){
        const token = await getToken(messaging, {
            vapidKey: 
            "BPvYGmSzl0uLZNvQ9Ylr09W8hveHMtcyAwfb8Y0LDp-RIRu-zCCv3JL2Rjtbqllmu_p7Ptz88AHSJwpBn5PWoY0"
        })
        console.log(token);
        
    }
}