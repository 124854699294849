
import * as React from 'react';

//router dom
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes/AppRoutes';

import LandingPage from './Pages/LandingPage';
import { generateToken } from './Notification/firebase';
import { useEffect } from 'react';
import { onMessage } from 'firebase/messaging';
import { messaging } from './Notification/firebase';

function App() {

  useEffect(()=> {
    generateToken();
    // onMessage(messaging, (payload)=> {
    //   console.log(payload);
    // })
  }, []);

  return (
     <>
    <Router>
      <AppRoutes />
    </Router>
    </>
  );
}

export default App;
