import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar';
import ViewOrder from '../../Components/Common/Factura_restManager/ViewOrder';
import ViewProduct from '../../Components/Common/Factura_restManager/ViewProduct';
import ViewUpdateOrder from '../../Components/Common/Factura_restManager/ViewUpdateOrder';

function FacturaViewOrderFast() {

  const [selectedItems, setSelectedItems] = useState([]);
  const [currentView, setCurrentView] = useState('product'); // Controla la vista actual
  const [selectedItem, setSelectedItem] = useState(null); // Almacena el ítem seleccionado

  const addItem = (item) => {
    setSelectedItems((prevItems) => [...prevItems, item]);
  };

  const removeItem = (itemToRemove) => {
    setSelectedItems((prevItems) =>
      prevItems.filter(
        (item) =>
          !(item.grupo_id === itemToRemove.grupo_id && item.nombre === itemToRemove.nombre)
      )
    );
  };

  const decreaseItemQuantity = (itemToDecrease) => {
    setSelectedItems((prevItems) => {
      // Encuentra el índice del ítem a disminuir
      const index = prevItems.findIndex((item) =>
        item.grupo_id === itemToDecrease.grupo_id &&
        item.nombre === itemToDecrease.nombre
      );
  
      // Si el ítem se encuentra, actualiza la cantidad o elimina el ítem si la cantidad es 0
      if (index !== -1) {
        const newItems = [...prevItems];
        const currentItem = newItems[index];
        const newQuantity = currentItem.cantidad - 1;
  
        // Si la nueva cantidad es mayor que 0, actualiza el ítem; si no, elimina el ítem
        if (newQuantity > 0) {
          newItems[index] = { ...currentItem, cantidad: newQuantity };
        } else {
          newItems.splice(index, 1); // Elimina el ítem del array
        }
        
        console.log('Updated items:', JSON.stringify(newItems, null, 2));
        return newItems;
      }
  
      return prevItems;
    });
  };
  
  

  

  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setCurrentView('update'); // Cambia la vista a `ViewUpdateOrder`
  };

  const handleBackToProductView = () => {
    setCurrentView('product'); // Cambia la vista de vuelta a `ViewProduct`
    setSelectedItem(null); // Opcional: Limpia el ítem seleccionado
  };

  return (
    <>
      <Navbar_sidebar>
        <Grid container spacing={2}>
          {/* Primera columna que ocupa 1/3 del ancho */}
          <Grid item xs={12} md={4}>
            <ViewOrder 
              selectedItems={selectedItems} 
              onItemSelect={handleItemSelect} // Pase la función como prop
            />
          </Grid>

          {/* Segunda columna que ocupa 2/3 del ancho */}
          <Grid item xs={12} md={8}>
            {currentView === 'product' ? (
              <ViewProduct addItem={addItem} />
            ) : (
              <ViewUpdateOrder
                item={selectedItem}
                addItem={addItem}
                onDone={handleBackToProductView}
                onRemove={removeItem} // Pasa la función para eliminar el ítem
                onDecrease={decreaseItemQuantity} // Pasa la nueva función para disminuir la cantidad
              />
            )}
          </Grid>
        </Grid>
      </Navbar_sidebar>
    </>
  );
}

export default FacturaViewOrderFast;

