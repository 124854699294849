import * as React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Paper, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

//icons
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DeleteIcon from '@mui/icons-material/Delete';


function ViewOrderPendingTable({ order }) {

    //params
    const { SucursalId } = useParams(); // Captura el parámetro de la URL

  if (!order) {
    return (
      <>
        <Card
          sx={{
            height: '80vh',
            position: 'relative',
            borderRadius: '12px',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '16px'
          }}
        >
          <ReceiptLongIcon sx={{ fontSize: 50, marginBottom: '16px' }} />
          <p>vacio</p>
        </Card>
      </>
    );
  }

 

  return (
    <>

<Card sx={{ height: '80vh', position: 'relative', borderRadius: '12px', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)' }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className='p-3'>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <a href='/Facturacion/restaurant'>fg</a>
            <Box sx={{ ml: 2 }}>
              Regresar
            </Box>
          </Box>
        </Typography>

          <div className="container mx-auto">
            <div className="" style={{ height: '55vh', overflowY: 'auto'}} >
              <div className="bg-gray-200">
                <Typography className="p-2"  
                      sx={{ 
                          fontFamily: 'Poppins', 
                          marginLeft:"24px"
                        }}>COMIDA</Typography>
              </div>
                {/* Item */}
              <div className="p-4" >
                {order.productos.map((producto, index) =>  (

                  <>
                  <div 
                    className="flex justify-between items-center cursor-pointer" 
                    key={index}
                   >
                    <div className="w-1/2 flex items-center">
                      <span className="text-center ml-3 bg-gray-300 rounded-full" 
                        style={{ 
                        width: '25px',
                        height: '25px',
                        lineHeight: '25px',
                        fontFamily: 'Poppins',
                        fontWeight: 600
                           }}>{producto.cantidad}</span>
                      <span className="text-right ml-5 font-bold text-sm"
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: 500
                      }}
                      >{producto.nombre}
                      </span>
                    </div>
                    
                    <div className="w-1/2 flex justify-end items-center">
                      <span className="text-left mr-5"
                        style={{
                          fontFamily: 'Poppins',
                          fontWeight: 500,
                          fontSize: '14px'
                        }}
                      >${producto.precio}</span>
                    </div>
                  </div>
                        {/* comentario */}
                    <div >
                    <span className="text-gray-500 ml-5 text-sm"
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        marginLeft: '62px'
                      }}
                    >
                      Sin azúcar
                    </span>
                    </div>

                  </>
                ))}
              </div>
            </div>
          </div>

          {/*Bottom  */}
        <div className="absolute bottom-0 w-full">
          <hr className="bg-gray-200 p-0.5"></hr>
          {/* costo and taxes */}
          <div className='container p-4'>
            <Button variant="contained" sx={{ width: '100%', marginTop: '5%' }} href={`/Facturacion/PaymentMethod/${SucursalId}`}>
              Pagar - ${order.precio}
            </Button>
          </div>
        </div>
      </Card>

    </>
  );
}

export default ViewOrderPendingTable;
