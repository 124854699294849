import React from 'react'

//components
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar'
import TableEmpty from '../../../Components/Common/Factura_restManager/Components/Table/TableEmpty'
import EmployeeTurn from './EmployeeTurn'

function TurnEmploye() {
  return (
    <>
    <Navbar_sidebar>
    <div className="flex flex-col md:flex-row gap-4">
          {/* columna 1 */}
      <div className="w-full md:w-2/3 p-4">
      <EmployeeTurn></EmployeeTurn>
      </div>
         {/* columna 2 */}
      <div className="w-full md:w-1/3 p-4">
      <TableEmpty></TableEmpty>
      </div>
    </div>
    </Navbar_sidebar>
    
    </>
  )
}

export default TurnEmploye