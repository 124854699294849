import React, { useState } from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import "../../../../Assets/Css/ModalSweetAlert.css";
import { useParams } from 'react-router-dom';

//api
import { ModuloFacture_OrderFast } from '../../../../Services/ModuloFacture_autoconta_restaurant/OrderFast/ModuloFacture_OrderFast';

//componentes
import Background from '../../HomeApp/Background';

//icons
import CategoryIcon from '@mui/icons-material/Category';

function CreateProducts({ onClose }) {
    //params
    const { SucursalId } = useParams(); // Captura el parámetro de la URL

    const [isJoinOneCompanyVisible, setIsJoinOneCompanyVisible] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        descripcion: '',
    });

    const handleCloseBackground = () => {
        setIsJoinOneCompanyVisible(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //cookies
    const UserId = Cookies.get('authUserId');
    const UserPhoto = Cookies.get('userPhoto');
    const Email = Cookies.get('Email');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes agregar lógica adicional si es necesario
        if (!formData.nombre || !formData.descripcion) {
            Swal.fire({
                title: 'Error',
                text: 'Por favor complete todos los campos',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    container: 'swal2-popup-custom'
                }
            });
            return;
        }

        ModuloFacture_OrderFast.CreateCategory({
            nombre: formData.nombre,
            descripcion: formData.descripcion,
            sucursal_id: SucursalId
        })
        .then(response => {
            Swal.fire({
                title: '¡Éxito!',
                text: 'Categoría creada exitosamente',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                    container: 'swal2-popup-custom'
                }
            }).then((result) => {
                // Si el usuario hace clic en "OK", recarga la página o redirige según necesites
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        })
        .catch(error => {
            Swal.fire({
                title: 'Error',
                text: 'No se pudo crear la categoría',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    container: 'swal2-popup-custom'
                }
            });
            console.error('Error al crear la categoría:', error);
        });
    };

    return (
        <Background onClose={onClose}>
            <Card sx={{ maxWidth: 345, padding: '20px' }}>
                {!isJoinOneCompanyVisible && (
                    <CardContent>
                        <Typography gutterBottom variant="h7" component="div">
                            <div className="flex justify-between items-center">
                                <div>
                                    <CategoryIcon /> Crear Categoria
                                </div>
                            </div>
                        </Typography>

                        <form className="mt-3" onSubmit={handleSubmit}>
                            <TextField
                                name="nombre"
                                label="Nombre"
                                variant="standard"
                                className="mb-5"
                                value={formData.nombre}
                                onChange={handleChange}
                            />
                            <TextField
                                name="descripcion"
                                label="descripcion"
                                variant="standard"
                                className="mb-5"
                                value={formData.descripcion}
                                onChange={handleChange}
                            />
                       
                            <div className="mt-5">
                                <Button type="submit" variant="contained" color="primary">
                                    crear
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                )}
                <CardActions />
            </Card>
        </Background>
    );
}

export default CreateProducts