import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function ViewUpdateOrder({ item, addItem, onDone, onRemove, onDecrease }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!item) {
    return null;
  }

  const handleIncrement = () => {
    addItem({ ...item, cantidad: item.cantidad + 1 });
  };

  const handleDecrement = () => {
    if (item.cantidad > 1) {
      onDecrease(item);
    } else {
      onRemove(item);
    }
  };
  

  return (
    <>
      <div className="flex gap-4 mt-3">
        <span>{item.id}</span>
        <div className="w-2/3 flex items-start gap-2">
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: 46,
              height: 46,
              borderRadius: '50%',
              minWidth: 0,
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleIncrement}
          >
            <AddIcon />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              width: 46,
              height: 46,
              borderRadius: '50%',
              minWidth: 0,
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span>{item.cantidad}</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: 46,
              height: 46,
              borderRadius: '50%',
              minWidth: 0,
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleDecrement}
          >
            <RemoveIcon />
          </Button>
        </div>
        <div className="w-1/3 flex items-end gap-2 md:hidden">
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            Dashboard
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
        </div>
        <div className="hidden md:flex md:w-full justify-end gap-2">
          <Button variant="outlined">Detalle</Button>
          <Button variant="outlined" onClick={() => onRemove(item)}>Remove</Button>
          <Button variant="outlined" onClick={onDone}>Done</Button>
        </div>
      </div>
      <div className="container mt-4">
        <h1 className="mb-3 p-3" style={{ fontSize: '1.5rem' }}>{item.nombre}</h1>
      </div>
    </>
  );
}

export default ViewUpdateOrder;