
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

//componentes
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar'
import TableEmployee from '../../../Components/Common/Factura_restManager/Components/TableEmployee';
import ProductsSettings from './ProductsSettings';

//icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function Setting() {

   //params
   const { SucursalId } = useParams(); // Captura el parámetro de la URL
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Navbar_sidebar>

   
       <Box sx={{ padding: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <a href={`/Facturacion/restaurant/${SucursalId}`} style={{ marginRight: '8px' }}>
              <ArrowBackIosIcon />
            </a>
            <Box>
              <Typography 
                variant="h1" 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontWeight: 600, 
                  fontSize: '1.5rem',
                  lineHeight: '1.2', 
                  marginBottom: '0.2rem', 
                  color: 'black' 
                }}
              >
                Setting
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  marginTop: '10px',
                  color: 'text.secondary', 
                  fontSize: '0.875rem' // Ajusta el tamaño del texto si es necesario
                }}
              >
                Aquí puedes configurar las opciones de la aplicación según tus preferencias.
              </Typography>
            </Box>
          </Box>
        </Box>

      <div className="container">


     <div>
      
      {/* tABS */}
        <Box sx={{ width: '100%', paddingX: '30px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"  >
          <Tab label="Empleados" {...a11yProps(0)} />
          <Tab label="Propinas" {...a11yProps(1)} />
          <Tab label="IVA" {...a11yProps(2)} />
          <Tab label="Productos" {...a11yProps(3)} />
          <Tab label="Crear productos movil" {...a11yProps(4)} />
        </Tabs>
      </Box>
      {/* Empleados */}
      <CustomTabPanel value={value} index={0} >
      <TableEmployee></TableEmployee>
      </CustomTabPanel>
      {/* Propinas */}
      <CustomTabPanel value={value} index={1}>
      <TextField
          required
          id="standard-required"
          label="Required"
          defaultValue="Hello World"
          variant="standard"
        />
      </CustomTabPanel>
      {/* Iva */}
      <CustomTabPanel value={value} index={2}>
      <TextField
          required
          id="standard-required"
          label="Required"
          defaultValue="Hello World"
          variant="standard"
        />
      </CustomTabPanel>
      {/* Productos */}
      <CustomTabPanel value={value} index={3}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingX: '16px' }}>
      {/* Columna izquierda */}
      <Box>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 600, 
            marginBottom: '4px', 
            color: 'black' 
          }}
        >
          Productos
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ color: 'text.secondary' }}
        >
           Gestiona tus productos, categorías, grupos e ítems desde un solo lugar
        </Typography>
      </Box>
    </Box>
      <ProductsSettings></ProductsSettings>
      </CustomTabPanel>
      {/* Create products Movil  */}
      <CustomTabPanel value={value} index={4}>
        Item 5
      </CustomTabPanel>
    </Box>
      
      
      
      
      
      
    
    </div>
     </div>

    </Navbar_sidebar>
  )
}

export default Setting