// api/companys
import Cookies from 'js-cookie';
import axios from 'axios';
import { UrlApi } from '../UrlApi';
const ApiUrl = UrlApi; // Url 

export const DashboardMain = {

    getVentasBySucursalRange: (startDate, endDate, SucursalId) => {

            return axios.get(`${ApiUrl}/ventasByVentas?sucursal_id=${SucursalId}&start_date=${startDate}&end_date=${endDate}`)
        .then(response => {
            // Manejar la respuesta exitosa
            console.log("data buscada: "+JSON.stringify(response.data));
            return response.data
        })
        .catch(error => {
            // Manejar el error
            console.error("Error en la solicitud:", error);
        });
        
        
    },

}