// api/orderfast
import Cookies from 'js-cookie';
import axios from 'axios';
import { UrlApi } from '../UrlApi';
const ApiUrl = UrlApi; // Url 

function getRandomLightColor() {
    const letters = 'BCDEF'; // Usar solo letras para colores claros
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
}

export const ModuloFacture_OrderFast = {

    //Categorias
    obtenerCategorias(sucursal_id) {
    
            return axios.get(`${ApiUrl}/categorias/sucursal/${sucursal_id}`)
        .then(response => {
            // Manejar la respuesta exitosa
            //console.log("Categorias"+JSON.stringify(response.data));
            return response.data
        })
        .catch(error => {
            // Manejar el error
            console.error("Error al obtener las categorías:", error);
        });
        
    },

    DeleteCategoriasBySucursal(Categoria_id) {
    
        return axios.delete(`${ApiUrl}/categorias/${Categoria_id}`)
    .then(response => {
        // Manejar la respuesta exitosa
        //console.log("Categorias"+JSON.stringify(response.data));
        return response.data
    })
    .catch(error => {
        // Manejar el error
        console.error("Error al obtener las categorías:", error);
    });
    
    },

    UpdateCategoriasBySucursal({ nombre, descripcion, sucursal_id }) {
    
        return axios.put(`${ApiUrl}/categorias/${sucursal_id}`, {
            nombre: nombre,
            descripcion: descripcion,
            sucursal_id: sucursal_id
        })
    .then(response => {
        // Manejar la respuesta exitosa
        //console.log("Categorias"+JSON.stringify(response.data));
        return response.data
    })
    .catch(error => {
        // Manejar el error
        console.error("Error al actualizar las categorías:", error);
    });
    
    },

    CreateCategory({ nombre, descripcion, sucursal_id }) {
        const color = getRandomLightColor();
        return axios.post(`${ApiUrl}/categorias`, {
            nombre: nombre,
            descripcion: descripcion,
            sucursal_id: sucursal_id,
            Color: color // Agregar el color generado
        })
        .then(response => {
            // Manejar la respuesta exitosa
            console.log("item" + JSON.stringify(response.data));
            return response.data;
        })
        .catch(error => {
            // Manejar el error
            console.error("Error al crear la categoría:", error);
        });
    },

    getGruposOfCategory(categoria_id) {
        return axios.get(`${ApiUrl}/grupos/categoria/${categoria_id}`)
        .then(response => {
            // Manejar la respuesta exitosa
            console.log("grupos"+JSON.stringify(response.data));
            return response.data
        })
        .catch(error => {
            // Manejar el error
            console.error("Error al obtener las grupos:", error);
        });
    
    },

    getItemsOfGroup(categoria_id) {
        return axios.get(`${ApiUrl}/items/grupo/-O2VQDoEXkgXPrs2gbrX`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            // Manejar el error
            console.error("Error al obtener las item:", error);
        });
    
    },

    //Ordenes
    CreateOrden({ empleado_id, estado, factura_id, productos,  mesa_id, precio, sucursal_id}) {
        return axios.post(`${ApiUrl}/ordenes`, {
            empleado_id: empleado_id,
            estado: estado,
            precio: precio,
            factura_id: factura_id,
            mesa_id: mesa_id,
            productos: productos,
            sucursal_id: sucursal_id,
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            // Manejar el error
            console.error("Error al obtener los item:", error);
        });
    },

    GetStatusBySucursal({status, sucursal_id}) {
        return axios.get(`${ApiUrl}/ordenes/${status}/${sucursal_id}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            // Manejar el error
            console.error("Error al obtener los item:", error);
        });
    },

    GetOrdenesPendientesBySucursal(sucursal_id) {
        return axios.get(`${ApiUrl}/ordenes/pendiente/${sucursal_id}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            // Manejar el error
            console.error("Error al obtener los item:", error);
        });
    },

    GetOrdenesFinalizadasBySucursal(sucursal_id) {
        return axios.get(`${ApiUrl}/ordenes/Finalizada/${sucursal_id}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            // Manejar el error
            console.error("Error al obtener los item:", error);
        });
    },

    UpdateStatusOrder({estado, orden_id}) {
        return axios.put(`${ApiUrl}/ordenes/${orden_id}/estado`,{
            estado: estado
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            // Manejar el error
            console.error("Error al obtener los item:", error);
        });
    },


 

}