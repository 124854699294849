import React from 'react';
import { useParams } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Swal from 'sweetalert2';
import "../../../Assets/Css/ModalSweetAlert.css"

// Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// API
import { ModuleFacture_Kitchen } from '../../../Services/ModuloFacture_autoconta_restaurant/Kitchen/ModuleFacture_Kitchen';
import { ModuloFacture_OrderFast } from '../../../Services/ModuloFacture_autoconta_restaurant/OrderFast/ModuloFacture_OrderFast';
//compoentes
import Loading from '../../../Components/Common/Loading';

// Query Client
const queryClient = new QueryClient();

const fetchOrdenesPendientes = async (SucursalId) => {
  const response = await ModuleFacture_Kitchen.getOrdenesPendientesBySucursal(SucursalId);
  if (!response.success) {
    throw new Error(response.message);
  }
  return Object.entries(response.data).map(([id, sucursal]) => ({ id, ...sucursal }));
};

function Kitchen() {
  const { SucursalId } = useParams(); // Captura el parámetro de la URL

  const { data: ordenesPending, error, isLoading } = useQuery(
    ['ordenesPendientes', SucursalId],
    () => fetchOrdenesPendientes(SucursalId),
    {
      refetchInterval: 5000, // Refetch every 10 seconds
    }
  );

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2
  };

  const UpdateStatusFinalizar = async(Order_id) => {
    const response = await ModuloFacture_OrderFast.UpdateStatusOrder({
      estado: "Finalizada",
      orden_id: Order_id
    }) .then(response => {
      Swal.fire({
        title: 'Completado!',
        text: 'Orden Completada',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          container: 'swal2-popup-custom '
        }
        })
        //.then((result) => {
      //   // Si el usuario hace clic en "OK", recarga la página o redirige según necesites
      //   if (result.isConfirmed) {
      //     window.location.reload();
      //   }
      //  });
  })
    .catch(error => {

      Swal.fire({
        title: 'Error',
        text: 'No se pudo completar la orden',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          container: 'swal2-popup-custom '
        }
      });
      
    });
  };



  if (isLoading) return <Loading></Loading>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Navbar_sidebar>
      
      <div className="flex justify-between items-center p-4">
  

        <div style={{ display: 'flex', alignItems: 'center' }} className='ml-2'>
        <a href={`/Facturacion/restaurant/${SucursalId}`}><ArrowBackIosIcon style={{ marginRight: 8 }} /></a>
        <Typography className="mt-2"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '1.3rem',
            color: 'black'
          }}
        >
          Cocina
        </Typography>
      </div>


        <div>
          <Button
            variant="contained"
            style={{ borderRadius: '25px', marginRight: '8px' }} // Añadido margen a la derecha
          >
            Ordenes Finalizadas
          </Button>
          <Button
            variant="contained"
            style={{ borderRadius: '25px', marginRight: '8px' }} // Añadido margen a la derecha
          >
            Agrupacion
          </Button>
          <a
            className="hover:bg-gray-200 font-bold py-2 px-4"
            style={{ borderRadius: '5px' }}
          >
            <MoreVertIcon />
          </a>
        </div>
      </div>

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex -ml-4 w-auto"
        columnClassName="pl-4 bg-clip-padding"
      >
      {ordenesPending.map(card => (
      <Paper elevation={3} key={card.id} className='mb-4 rounded-lg p-4'>
        <div className="bg-orange-500 p-2 rounded mb-2 text-white flex justify-between">
          <span>#{card.mesa_id}</span>
          <span>2:31 PM</span>
        </div>

        {/* Mostrar productos si existen */}
        {card.productos && card.productos.length > 0 && (
          <div className="mt-4 mr-2 mb-1">
            <ul className="list-disc pl-5">
              {card.productos.map((producto, index) => (
                <li key={index} className="flex justify-between">
                  <span> <FormControlLabel
            control={<Radio size="small" />}
            label={
              <span className="flex items-center">
                {producto.cantidad} {producto.nombre}
              </span>
            }
            style={{ marginRight: '8px' }} // Ajusta el margen según sea necesario
          /> </span>
                  <span><CheckCircleIcon style={{ fontSize: "14px" }} /></span>
                </li>
              ))}
            </ul>

          {/* Buttom */}
          <div className="flex justify-center space-x-4 mt-3 ">
            <Button
              variant="outlined"
              size="small" // Usar el tamaño pequeño predefinido en MUI
              className="text-xs" // Ajustar el tamaño del texto si es necesario
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              size="small" // Usar el tamaño pequeño predefinido en MUI
              style={{ backgroundColor: '#9e9e9e', color: '#fff' }} // Estilo en línea para gris
              className="text-xs" // Ajustar el tamaño del texto si es necesario
              onClick={() => UpdateStatusFinalizar(card.id)}
            >
              Completar
            </Button>
          </div>  {/* end Buttom */}

          </div>
          )}

      </Paper>
      ))}
      </Masonry>
    </Navbar_sidebar>
  );
}

export default function KitchenWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <Kitchen />
    </QueryClientProvider>
  );
}
