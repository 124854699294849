import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Grid, Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

//icons
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';


const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );
  
  const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      width: '100%', // Ajusta el ancho aquí,
    }));

function TableEmpty() {
  return (
    <>
    <Card
      sx={{
        height: '80vh',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '16px'
      }}
    >
      <ReceiptLongIcon sx={{ fontSize: 50, marginBottom: '16px' }} />
      <p>vacio</p>
    </Card>
    </>
  )
}

export default TableEmpty