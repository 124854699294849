import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

import Button from '@mui/joy/Button';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import SvgIcon from '@mui/joy/SvgIcon';

//components
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar';
import ChartFacture from '../../../Components/Common/Factura_restManager/Components/ChartFacture';
//icons
import SellIcon from '@mui/icons-material/Sell';

function Dashboard() {
  return (
    <>
    <Navbar_sidebar>

      
     <ChartFacture></ChartFacture>
    
    </Navbar_sidebar>
    </>
  )
}

export default Dashboard