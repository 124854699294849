// api/companys
import Cookies from 'js-cookie';
import axios from 'axios';
import { UrlApi } from '../UrlApi';
const ApiUrl = UrlApi; // Url 

export const ModuleFacture_Kitchen = {

    getOrdenesPendientesBySucursal: (Sucursal_id) => {

    return axios.get(`${ApiUrl}/ordenes/pendiente/${Sucursal_id}`)
    .then(response => {
        return response.data
    })
    .catch(error => {
        console.error("Error en la solicitud:", error);
    });
    },
    
}