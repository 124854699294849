import React from 'react';
import Datepicker from 'react-tailwindcss-datepicker';

const DatepickerComponent = ({ value, onChange, showShortcuts, showFooter, configs, className }) => {
  return (
    <div className={`datepicker-container ${className}`}>
      <Datepicker
        value={value}
        onChange={onChange}
        showShortcuts={showShortcuts}
        showFooter={showFooter}
        configs={configs}
      />
    </div>
  );
};

export default DatepickerComponent;
