import React, { useState } from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import "../../../../Assets/Css/ModalSweetAlert.css";

import { Companys } from '../../../../Services/ApiCompany/Companys';
import { ModuleFacture } from '../../../../Services/ModuloFacture_autoconta_restaurant/ModuleFacture';

//componentes
import Background from '../../HomeApp/Background';

//icons
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';

function BackgroundCreateSucursales({ onClose }) {
    const [isJoinOneCompanyVisible, setIsJoinOneCompanyVisible] = useState(false);
    const [codigo, setCodigo] = useState(''); // Nuevo estado para el campo Codigo
    const [formData, setFormData] = useState({
        nombre: '',
        email: '',
        access_key: '',
        ubicacion: ''
    });

    const handleCodigoChange = (e) => {
        setCodigo(e.target.value);
    };

    const handleCloseBackground = () => {
        setIsJoinOneCompanyVisible(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //cookies
    const UserId = Cookies.get('authUserId');
    const UserPhoto = Cookies.get('userPhoto');
    const Email = Cookies.get('Email');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await ModuleFacture.postCreateSucursales();
            console.log('Respuesta del servidor:', response);
      
        } catch (error) {
            console.error('Error al crear la empresa:', error.response?.data || error.message);
            Swal.fire({
                title: 'Error',
                text: 'No se pudo crear la compañía',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    container: 'swal2-popup-custom '
                }
            });
        }
    };
    

    const joinOneCompany = (e) => {
        e.preventDefault();
        // Aquí puedes agregar lógica adicional si es necesario
        Companys.postJoinOneCompany({ Codigo: codigo, UserId: UserId, UserPhoto: UserPhoto, Email: Email })
        .then(response => {
            Swal.fire({
                title: '¡Éxito!',
                text: 'Te uniste exitosamente.',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal2-popup-custom '
                }
            }).then((result) => {
                if (result.value) {
                    // Si el usuario presiona "Ok", refresca la página
                    window.location.reload();
                }
            });
        })
        .catch(error => {
            Swal.fire({
                title: 'Error',
                text: 'Ha ocurrido un error al intentar unirse a la empresa.',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal2-popup-custom '
                }
            }).then((result) => {
                if (result.value) {
                    // Si el usuario presiona "Ok", refresca la página
                    window.location.reload();
                }
            });
            console.error('Error al intentar unirse a la empresa:', error);
        });
    };

    return (
        <Background onClose={onClose}>
            <Card sx={{ maxWidth: 345, padding: '20px' }}>
                {!isJoinOneCompanyVisible && (
                    <CardContent>
                        <Typography gutterBottom variant="h7" component="div">
                            <div className="flex justify-between items-center">
                                <div>
                                    <BusinessIcon /> Crear Sucursal
                                </div>
                                <Button onClick={() => setIsJoinOneCompanyVisible(!isJoinOneCompanyVisible)}>
                                    <SettingsIcon />
                                </Button>
                            </div>
                        </Typography>

                        <form className="mt-3" onSubmit={handleSubmit}>
                            <TextField
                                name="nombre"
                                label="Nombre"
                                variant="standard"
                                className="mb-5"
                                value={formData.nombre}
                                onChange={handleChange}
                            />
                            <TextField
                                name="email"
                                label="Email"
                                variant="standard"
                                className="mb-5"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <TextField
                                name="ubicacion"
                                label="Ubicacion"
                                variant="standard"
                                value={formData.ubicacion}
                                onChange={handleChange}
                            />
                            <TextField
                                name="access_key"
                                label="Access Key"
                                variant="standard"
                                value={formData.access_key}
                                onChange={handleChange}
                            />
                            <div className="mt-5">
                                <Button type="submit" variant="contained" color="primary">
                                    Crear Empresa
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                )}
                {isJoinOneCompanyVisible && (
                    <CardContent>
                        <Typography gutterBottom variant="h7" component="div">
                            <div className="flex justify-between items-center">
                                <div>
                                    <BusinessIcon /> Agregar Company
                                </div>
                                <Button onClick={() => setIsJoinOneCompanyVisible(!isJoinOneCompanyVisible)}>
                                    <SettingsIcon />
                                </Button>
                            </div>
                        </Typography>

                        <form className="mt-3" onSubmit={joinOneCompany}>
                            <TextField
                                name="Codigo"
                                label="Codigo"
                                variant="standard"
                                className="mb-5"
                                value={codigo}
                                onChange={handleCodigoChange}
                            />
                            <div className="mt-5">
                                <Button type="submit" variant="contained" color="primary">
                                    Agregar
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                )}
                <CardActions />
            </Card>
        </Background>
    );
}

export default BackgroundCreateSucursales;
