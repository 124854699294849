import React from 'react'
import { Paper} from '@mui/material'
import { Grid, Avatar, Typography, Box } from '@mui/material';

//icons
import PaymentsIcon from '@mui/icons-material/Payments';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';

function TableEmployee() {
  return (
    <>
    {/* Title */}
    <Paper
      elevation={3}
      sx={{
        background: '#808080',
        color: 'white',
        display: { xs: 'none', md: 'block' }, // Oculta en pantallas XS y SM, muestra en MD y arriba
      }}
    >
      <Grid container className='mb-3'>
        <Grid item xs={3} className='px-4 p-2'>
          Empleado
        </Grid>
        <Grid item xs={3} className='px-4 p-2'>
          Info
        </Grid>
        <Grid item xs={3} className='px-4 p-2'>
          <Typography sx={{ marginLeft: '15px' }}>
            Costo
          </Typography>
        </Grid>
        <Grid item xs={3} className='px-4 p-2'>
          Direcion
        </Grid>
      </Grid>
    </Paper>

    {/* Item */}
    <Paper elevation={3}>
    <Grid container>
    <Grid item md={3} className='p-4'>
        <Box className="text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
          <Box display="flex" alignItems="center" gap={2}>
            <img
              className="object-cover w-8 h-8 rounded-full"
              src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
              alt=""
            />
            <Box>
              <Typography
                variant="body2"
                color="textPrimary"
                sx={{ fontSize: '0.875rem', fontWeight: '500' }}
              >
                Arthur Melo
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                sx={{ fontSize: '0.75rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                Mesero
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>

      {/* Contact Information */}
      <Grid item md={3} className='p-4'>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <CallIcon sx={{ height: '17px', width: '17px' }} />
            <Typography
              variant="body2"
              color="textPrimary"
              sx={{ fontSize: '0.75rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              +57 3006829856
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <MailIcon sx={{ height: '17px', width: '17px' }} />
            <Typography
              variant="body2"
              color="textPrimary"
              sx={{ fontSize: '0.75rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              authurmelo@example.com
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* Payment Information */}
      <Grid item md={3} className='p-4'>
        <Box display="flex" alignItems="center" gap={1}>
          <PaymentsIcon sx={{ height: '17px', width: '17px' }} />
          <Typography
            variant="body2"
            color="textPrimary"
            sx={{ fontSize: '0.75rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            $1.500.000
          </Typography>
        </Box>
      </Grid>

      {/* Address Information */}
      <Grid item md={3} className='p-4'>
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ fontSize: '0.75rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          Carrera 15A #43A-21
        </Typography>
      </Grid>
    </Grid>
    </Paper>

    </>
  )
}

export default TableEmployee