// api/settings
import Cookies from 'js-cookie';
import axios from 'axios';
import { UrlApi } from '../UrlApi';
const ApiUrl = UrlApi; // Url 

export const ModuleFacture_Gastos = {

    //Gastos
    CreateGastos({ monto, descripcion, fecha, sucursal_id }) {
    return axios.post(`${ApiUrl}/sucursales/${sucursal_id}/gastos`, {
        monto: monto,
        descripcion: descripcion,
        fecha: fecha,
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        console.error("Error al crear el gasto:", error);
    });
    },

    GetGastosBySucursal(sucursal_id) {
        return axios.get(`${ApiUrl}/sucursales/${sucursal_id}/gastos`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error("Error al obtener los gastos:", error);
        });
    },

    UpdateGastosById({ monto, descripcion, fecha, sucursal_id }) {
        return axios.put(`${ApiUrl}/gastos/${sucursal_id}`, {
            monto: monto,
            descripcion: descripcion,
            fecha: fecha,
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error("Error al actualizar el gasto:", error);
        });
    },

    DeleteGastoById(id_gasto) {
        return axios.delete(`${ApiUrl}/gastos/${id_gasto}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error("Error al obtener el gasto:", error);
        });
    },

    //box
    GetGastosBySucursalAll(Sucursal_id, Start_date, End_date) {
        return axios.get(`${ApiUrl}/gastosMontoTotal?sucursal_id=${Sucursal_id}&start_date=${Start_date}&end_date=${End_date}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error("Error al obtener los gastos:", error);
        });
    },

    GetGastosBySucursalAllByMethodPay(Sucursal_id, Start_date, End_date) {
        return axios.get(`${ApiUrl}/gastos/metodos_pago/${Sucursal_id}?start_date=${Start_date}&end_date=${End_date}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error("Error al obtener los gastos:", error);
        });
    },

    GetGastosBySucursalAllByCategory(Sucursal_id, Start_date, End_date) {
        return axios.get(`${ApiUrl}/sucursales/${Sucursal_id}?start_date=${Start_date}&end_date=${End_date}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error("Error al obtener los gastos:", error);
        });
    },
    
}