import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

function BarChart({ data, labels }) {
    const chartRef = useRef(null);
    let chartInstance = null;

    useEffect(() => {
        if (chartRef.current) {
            // Si hay una instancia anterior, destrúyela
            if (chartInstance) {
                chartInstance.destroy();
            }

            // Crea una nueva instancia del gráfico
            chartInstance = new Chart(chartRef.current, {
                type: 'bar',
                data: {
                    labels: labels || [], // Usa las etiquetas pasadas como prop
                    datasets: [{
                        label: 'Ventas por Mes',
                        data: data || [], // Usa los datos pasados como prop
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            // ... otros colores
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            // ... otros colores
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        }

        // Cleanup function para destruir el gráfico cuando el componente se desmonte
        return () => {
            if (chartInstance) {
                chartInstance.destroy();
            }
        };
    }, [data, labels]); // Dependencias para actualizar el gráfico cuando cambien los datos o las etiquetas

    return (
        <>
            <canvas ref={chartRef}></canvas>
        </>
    );
}

export default BarChart;
