import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Swal from 'sweetalert2';
import "../../../Assets/Css/ModalSweetAlert.css"

// api
import { ModuloFacture_OrderFast } from '../../../Services/ModuloFacture_autoconta_restaurant/OrderFast/ModuloFacture_OrderFast';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const groupItemsById = (items) => {
  const groupedItems = items.reduce((acc, item) => {
    const key = `${item.categoria_id}-${item.grupo_id}-${item.nombre}`;
    if (!acc[key]) {
      acc[key] = { ...item, cantidad: 0 };
    }
    acc[key].cantidad += 1;
    return acc;
  }, {});

  return Object.values(groupedItems);
};

function ViewOrder({ selectedItems, onItemSelect }) {
  const { SucursalId } = useParams();
  const navigate = useNavigate();
  const UserId = Cookies.get('authUserId');
  const [totalPrecio, setTotalPrecio] = useState(0);
  const [ochoPorCiento, setOchoPorCiento] = useState(0);
  const [totalDefinitivo, setTotalDefinitivo] = useState(0);

  const CreateOrder = async (e) => {
    e.preventDefault();
    try {
      const response = await ModuloFacture_OrderFast.CreateOrden({
        empleado_id: UserId,
        estado: "pendiente",
        factura_id: "3",
        precio: totalDefinitivo,
        productos: selectedItems,
        mesa_id: "5",
        sucursal_id: SucursalId
      }) .then(response => {
        Swal.fire({
          title: '¡Exito!',
          text: 'Orden creada exitosamente',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            container: 'swal2-popup-custom '
          }
          })
          //.then((result) => {
        //   // Si el usuario hace clic en "OK", recarga la página o redirige según necesites
        //   if (result.isConfirmed) {
        //     window.location.reload();
        //   }
        //  });
    })
      .catch(error => {
  
        Swal.fire({
          title: 'Error',
          text: 'No se pudo crear la orden',
          icon: 'error',
          confirmButtonText: 'OK',
          customClass: {
            container: 'swal2-popup-custom '
          }
        });
        
      });

    } catch (error) {
      console.log('Error:' + error);
    }
  };

  const PayOrder = async () => {
    try {
      const response = await ModuloFacture_OrderFast.CreateOrden({
        empleado_id: UserId,
        estado: "pendiente",
        factura_id: "3",
        precio: totalDefinitivo,
        productos: selectedItems,
        mesa_id: "5",
        sucursal_id: SucursalId
      });

      navigate(`/Facturacion/PaymentMethod/${SucursalId}`);

    } catch (error) {
      console.log('Error:' + error);
    }
  };

  useEffect(() => {
    const consolidatedItems = groupItemsById(selectedItems);
    
    console.log("items seleccionados: " + JSON.stringify(consolidatedItems));

    const total = consolidatedItems.reduce((sum, item) => sum + item.precio * item.cantidad, 0);
    setTotalPrecio(total);

    const ochoPorCiento = total * 0.08;
    setOchoPorCiento(ochoPorCiento);

    const totalDefinitivo = total + ochoPorCiento;
    setTotalDefinitivo(totalDefinitivo);
  }, [selectedItems]);

  return (
    <>
      <Card sx={{ height: '80vh', position: 'relative', borderRadius: '12px', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)' }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className='p-3'>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <a href='/Facturacion/restaurant'><ArrowBackIcon /></a>
            <Box sx={{ ml: 2 }}>
              Regresar
            </Box>
          </Box>
        </Typography>

        <form onSubmit={CreateOrder}>
          <div className="container mx-auto">
            <div className="" style={{ height: '48vh', overflowY: 'auto'}} >
              <div className="bg-gray-200">
                <Typography className="p-2"  
                      sx={{ 
                          fontFamily: 'Poppins', 
                          marginLeft:"24px"
                        }}>COMIDA</Typography>
              </div>
                {/* Item */}
              <div className="p-4" >
                {groupItemsById(selectedItems).map((item, index) => (

                  <>
                  <div 
                    className="flex justify-between items-center cursor-pointer" 
                    key={index}
                    onClick={() => onItemSelect(item)} // Llama a la función al hacer clic en el ítem
                  >
                    <div className="w-1/2 flex items-center">
                      <span className="text-center ml-3 bg-gray-300 rounded-full" 
                        style={{ 
                        width: '25px',
                        height: '25px',
                        lineHeight: '25px',
                        fontFamily: 'Poppins',
                        fontWeight: 600
                           }}>{item.cantidad}</span>
                      <span className="text-right ml-5 font-bold text-sm"
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: 500
                      }}
                      >{item.nombre}
                      </span>
                    </div>
                    
                    <div className="w-1/2 flex justify-end items-center">
                      <span className="text-left mr-5"
                        style={{
                          fontFamily: 'Poppins',
                          fontWeight: 500,
                          fontSize: '14px'
                        }}
                      >${item.precio * item.cantidad}</span>
                    </div>
                  </div>
                        {/* comentario */}
                    <div >
                    <span className="text-gray-500 ml-5 text-sm"
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        marginLeft: '62px'
                      }}
                    >
                      Sin azúcar
                    </span>
                    </div>

                  </>
                ))}
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 w-full">
            <hr className="bg-gray-200 p-0.5"></hr>
            <div className='container p-4'>
              <div className="flex justify-between">
                <p>Total:</p>
                <p>${totalPrecio}</p>
              </div>

              <div className="flex justify-between ">
                <Typography 
                  variant="h1" 
                  sx={{ 
                    fontFamily: 'Roboto', 
                    fontWeight: 500, 
                    fontSize: '1rem',
                    lineHeight: '1.2', 
                    marginBottom: '0.4rem',
                    color: 'black' 
                  }}
                >
                  Ipo consumo:
                </Typography>
                <Typography 
                  variant="h1" 
                  sx={{ 
                    fontFamily: 'Roboto', 
                    fontWeight: 500, 
                    fontSize: '1rem',
                    lineHeight: '1.2', 
                    marginBottom: '0.4rem',
                    color: 'black' 
                  }}
                >
                  ${ochoPorCiento}
                </Typography>
              </div>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '5%' }}>
                <Button variant="outlined" sx={{ width: '48%' }} onClick={PayOrder}>
                  Pagar - ${totalDefinitivo}
                </Button>
                <Button variant="contained" sx={{ width: '48%' }} type="submit">
                  Crear - ${totalDefinitivo}
                </Button>
              </Box>
            </div>
          </div>
        </form>
      </Card>
    </>
  );
}

export default ViewOrder;
