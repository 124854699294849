// api/companys
import Cookies from 'js-cookie';
import axios from 'axios';
import { UrlApi } from '../UrlApi';
const ApiUrl = UrlApi; // Url 

export const ModuleFacture_Employee = {

    getEmployeeBySucursal: (sucursal_id) => {

            return axios.post(`${ApiUrl}/getEmpleadosBySucursalId`, { sucursal_id: sucursal_id })
        .then(response => {
            // Manejar la respuesta exitosa
            console.log("empleados de la sucursal: "+JSON.stringify(response.data));
            return response.data
        })
        .catch(error => {
            // Manejar el error
            console.error("Error en la solicitud:", error);
        });
        
        
    },

}