import * as React from 'react';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

//components
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar';

//img
import MoneyMethod from './Img/MoneyMethod.png';
import MasterCard from './Img/Mastercard.png';

const Card = styled(Paper)(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2), // Espacio entre tarjetas
  maxWidth: 800,
  margin: '0 auto',
  transition: 'transform 0.3s ease', // Animación suave
  transform: selected ? 'translateX(10px)' : 'none', // Mover tarjeta seleccionada
}));

const Image = styled('img')(({ theme }) => ({
  width: 30,
  height: 30,
  objectFit: 'contain',
  marginRight: theme.spacing(2),
}));

const Line = styled('div')(({ theme }) => ({
  height: '100%',
  width: 2,
  backgroundColor: theme.palette.divider,
  marginRight: theme.spacing(2),
}));

const Content = styled('div')({
  flexGrow: 1,
});

const ChooseMethodPay = () => {
  //params
  const { SucursalId } = useParams(); // Captura el parámetro de la URL

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <Navbar_sidebar>
        <div className="flex flex-col items-center w-full">
          <div className="w-full max-w-md mx-auto">
            <h1 className='mt-5 mb-5 text-center text-xl font-bold'>Elegir método de pago</h1>
         
            <Card elevation={3} selected={selectedOption === 'cash'}>
              <Image src={MoneyMethod} alt="Efectivo" />
              <Line />
              <Content>
                <Typography variant="h6">Efectivo</Typography>
              </Content>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="cashRadio"
                  name="paymentOption"
                  className="form-radio h-5 w-5 text-blue-600 mr-2"
                  checked={selectedOption === 'cash'}
                  onChange={() => handleOptionChange('cash')}
                />
                <label htmlFor="cashRadio" className="text-gray-600"></label>
              </div>
            </Card>
            <br/>
            <Card elevation={3} selected={selectedOption === 'card'}>
              <Image src={MasterCard} alt="Tarjeta" />
              <Line />
              <Content>
                <Typography variant="h6">Tarjeta</Typography>
              </Content>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="cardRadio"
                  name="paymentOption"
                  className="form-radio h-5 w-5 text-blue-600 mr-2"
                  checked={selectedOption === 'card'}
                  onChange={() => handleOptionChange('card')}
                />
                <label htmlFor="cardRadio" className="text-gray-600"></label>
              </div>
            </Card>

            <br/>
            <Card elevation={3} selected={selectedOption === 'transfer'}>
              <Image src={MasterCard} alt="Transferencia" />
              <Line />
              <Content>
                <Typography variant="h6">Transferencia</Typography>
              </Content>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="transferRadio"
                  name="paymentOption"
                  className="form-radio h-5 w-5 text-blue-600 mr-2"
                  checked={selectedOption === 'transfer'}
                  onChange={() => handleOptionChange('transfer')}
                />
                <label htmlFor="transferRadio" className="text-gray-600"></label>
              </div>
            </Card>

            {selectedOption === 'cash' && (
              <div className="bg-gray-300 mt-5 p-4 w-full mb-5">
                <h1>Contenido adicional solo para pago en efectivo</h1>
              </div>
            )}
          </div>
          {/* Button */}
          <div className="w-full max-w-md mx-auto mt-5">
            <h1 className="text-center mb-3">Total a pagar $100.000</h1>
            <Button variant="contained" className='w-full' href={`/Facturacion/MarkedAsPaid/${SucursalId}`}>
              Marcar como pagado
            </Button>
          </div>
        </div>
      </Navbar_sidebar>
    </>
  );
}

export default ChooseMethodPay;
