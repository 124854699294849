import React from 'react'

//components
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar'
import BoxGastoView from '../../../Components/Common/Factura_restManager/Components/BoxGastoView'
import TableGastos from '../../../Components/Common/Factura_restManager/Components/Table/TableGastos'

function Bill() {
  return (
    <Navbar_sidebar>
        <div className="flex flex-col md:flex-row gap-4">
          {/* columna 1 */}
      <div className="w-full md:w-2/3 p-4">
      <TableGastos></TableGastos>
      </div>
         {/* columna 2 */}
      <div className="w-full md:w-1/3 p-4">
      <BoxGastoView></BoxGastoView>
      </div>
    </div>
    </Navbar_sidebar>
  )
}

export default Bill