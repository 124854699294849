import React from 'react'

//compnents
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar'
import BoxInvoicesView from '../../../Components/Common/Factura_restManager/Components/BoxInvoicesView'
import TableInvoices from '../../../Components/Common/Factura_restManager/Components/Table/TableInvoices'


function InvoicesView() {
  return (
    <>
    <Navbar_sidebar>
    <div className="flex flex-col md:flex-row gap-4">
          {/* columna 1 */}
      <div className="w-full md:w-2/3 p-4">
      <TableInvoices></TableInvoices>
      </div>
         {/* columna 2 */}
      <div className="w-full md:w-1/3 p-4">
      <BoxInvoicesView></BoxInvoicesView>
      </div>
    </div>
    </Navbar_sidebar>
    </>
  )
}

export default InvoicesView