import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

//icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    width: '100%',
  }));

function ViewTable() {
  return (
    <>
       <Card sx={{ height: '80vh', position: 'relative', borderRadius: '12px',
   boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', backgroundColor: '#2c2b30'
    }}>

    <div className="" style={{ backgroundColor: '#131313' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <a href='/Facturacion/restaurant'><ArrowBackIcon style={{ color: 'white' }} /></a>
          <Box sx={{ ml: 1 }} className="text-white">
            Home
          </Box>
        </Box>
        </Typography>
      </CardContent>

        {/* Table */}  
        <Grid container spacing={3} className='pl-5 px-5'>
        <Grid item xs={4}>
          <Item>
          <div className="flex justify-center items-center">
            <span className="text-center">
                Disponibles
            <br/><span>5</span>
            </span>
          </div>
        </Item>
        </Grid>
        <Grid item  xs={4}>
        <Item>
        <div className="flex justify-center items-center">
            <span className="text-center">
                Reservadas
            <br/><span>5</span>
            </span>
          </div>
        </Item>
        </Grid>
        <Grid item  xs={4}>
        <Item>
        <div className="flex justify-center items-center">
            <span className="text-center">
                Activas
            <br/><span>2</span>
            </span>
          </div>
        </Item>
        </Grid>
        </Grid>       

        {/*  */}
        <Grid container spacing={3} className='p-5'>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          endIcon={<ArrowDropDownIcon style={{ color: 'white' }} />}
          style={{
            borderColor: 'white',
            color: 'white',
            width: '100%', // Asegura que el botón ocupe todo el ancho del Grid item
            height: '40px', // Altura fija para todos los botones
          }}
        >
          Today
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          endIcon={<ArrowDropDownIcon style={{ color: 'white' }} />}
          style={{
            borderColor: 'white',
            color: 'white',
            width: '100%', // Asegura que el botón ocupe todo el ancho del Grid item
            height: '40px', // Altura fija para todos los botones
          }}
        >
          Ver todo
        </Button>
      </Grid>
    </Grid>
    
    </div>
      {/* 
      <div className="absolute bottom-0 w-full">
      <div className="container p-4">
        <Button
          variant="contained"
          className="w-full mt-5 flex items-center justify-center"
        >
          <AddIcon className="mr-2" />
          Añadir una orden
        </Button>
      </div>
      </div> 
      */}

    </Card>
    </>
  )
}

export default ViewTable