import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Circle, Text } from 'react-konva';
import { Box, Button } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

function TableScroll() {
  const [circles, setCircles] = useState({
    Salon: [{ x: 100, y: 100, radius: 50, number: 1 }],
    Terraza: [],
  });
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [currentCircleIndex, setCurrentCircleIndex] = useState(null);
  const [scale, setScale] = useState(1);
  const [selectedButton, setSelectedButton] = useState('Salon');
  const containerRef = useRef(null);
  const stageRef = useRef(null);

  useEffect(() => {
    // Set initial view from URL parameter
    const queryParams = new URLSearchParams(window.location.search);
    const view = queryParams.get('view');
    if (view === 'Terraza' || view === 'Salon') {
      setSelectedButton(view);
    }
  }, []);

  useEffect(() => {
    // Update URL with the selected view
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('view', selectedButton);
    window.history.pushState(null, '', '?' + queryParams.toString());
  }, [selectedButton]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenuVisible && !containerRef.current.contains(event.target)) {
        setContextMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contextMenuVisible]);

  useEffect(() => {
    const updateStageSize = () => {
      if (stageRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        stageRef.current.width(clientWidth);
        stageRef.current.height(clientHeight);
      }
    };

    updateStageSize();
    window.addEventListener('resize', updateStageSize);

    return () => {
      window.removeEventListener('resize', updateStageSize);
    };
  }, []);

  const handleStageClick = (e) => {
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    if (!e.target.attrs.stroke) {
      const newCircleNumber = circles[selectedButton].length + 1;
      setCircles(prevCircles => ({
        ...prevCircles,
        [selectedButton]: [...prevCircles[selectedButton], { x: pointerPosition.x / scale, y: pointerPosition.y / scale, radius: 50, number: newCircleNumber }],
      }));
      setContextMenuVisible(false);
    }
  };

  const handleCircleContextMenu = (e, index) => {
    e.evt.preventDefault();
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    
    setContextMenuPosition({ x: pointerPosition.x, y: pointerPosition.y });
    setCurrentCircleIndex(index);
    setContextMenuVisible(true);
  };

  const handleRemove = () => {
    setCircles(prevCircles => {
      const newCircles = [...prevCircles[selectedButton]];
      newCircles.splice(currentCircleIndex, 1);
      return {
        ...prevCircles,
        [selectedButton]: newCircles,
      };
    });
    setContextMenuVisible(false);
  };

  const handleChange = () => {
    setCircles(prevCircles => {
      const newCircles = [...prevCircles[selectedButton]];
      newCircles[currentCircleIndex] = { ...newCircles[currentCircleIndex], radius: newCircles[currentCircleIndex].radius + 10 };
      return {
        ...prevCircles,
        [selectedButton]: newCircles,
      };
    });
    setContextMenuVisible(false);
  };

  const handleZoomIn = () => {
    setScale(prevScale => {
      const newScale = Math.min(prevScale * 1.2, 5);
      stageRef.current.scale({ x: newScale, y: newScale });
      return newScale;
    });
  };

  const handleZoomOut = () => {
    setScale(prevScale => {
      const newScale = Math.max(prevScale / 1.2, 0.2);
      stageRef.current.scale({ x: newScale, y: newScale });
      return newScale;
    });
  };

  return (
    <div ref={containerRef} onContextMenu={(e) => e.preventDefault()} style={{ position: 'relative' }}>
      {/* Sites */}
      <div className='mb-2' style={{ width: '95%', height: '50px', display: 'flex', alignItems: 'center', borderRadius: "25px", backgroundColor: '#2c2b30' }}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }} className='p-5'>
          <Button
            onClick={() => setSelectedButton('Salon')}
            style={{
              backgroundColor: selectedButton === 'Salon' ? '#131313' : '#fff',
              borderRadius: '30px',
              color: selectedButton === 'Salon' ? '#fff' : '#000',
              minWidth: '100px',
            }}
          >
            Salon
          </Button>
          <Button
            onClick={() => setSelectedButton('Terraza')}
            style={{
              backgroundColor: selectedButton === 'Terraza' ? '#131313' : '#fff',
              borderRadius: '30px',
              color: selectedButton === 'Terraza' ? '#fff' : '#000',
              minWidth: '100px',
            }}
          >
            Terraza
          </Button>
        </div>
      </div>
      {/* End Sites */}

      <Box
        sx={{
          display: 'flex',
          marginRight: "35px",
          justifyContent: 'flex-end',
          gap: 1,
          padding: 1,
        }}
      >
        <Button
          variant="contained"
          onClick={handleZoomIn}
          sx={{
            backgroundColor: 'white',
            color: 'black',
            fontSize: '0.8rem',
            '&:hover': {
              backgroundColor: 'lightgray',
            },
          }}
        >
          <ZoomInIcon />
        </Button>
        <Button
          variant="contained"
          onClick={handleZoomOut}
          sx={{
            backgroundColor: 'white',
            color: 'black',
            fontSize: '0.8rem',
            '&:hover': {
              backgroundColor: 'lightgray',
            },
          }}
        >
          <ZoomOutIcon />
        </Button>
      </Box>

      <Stage
        height={450}
        width={800}
        ref={stageRef}
        onClick={handleStageClick}
      >
        <Layer>
          {circles[selectedButton].map((circle, index) => (
            <React.Fragment key={index}>
              <Circle
                x={circle.x * scale}
                y={circle.y * scale}
                stroke="black"
                radius={circle.radius * scale}
                draggable
                onContextMenu={(e) => handleCircleContextMenu(e, index)}
                onDragEnd={(e) => {
                  const newCircles = circles[selectedButton].slice();
                  newCircles[index] = { ...circle, x: e.target.x() / scale, y: e.target.y() / scale };
                  setCircles(prevCircles => ({
                    ...prevCircles,
                    [selectedButton]: newCircles,
                  }));
                }}
              />
              <Text
                x={circle.x * scale}
                y={circle.y * scale}
                text={circle.number.toString()}
                fontSize={20 * scale}
                fill="black"
                align="center"
                verticalAlign="middle"
                offsetX={circle.radius * scale / 2}
                offsetY={10 * scale}
                draggable
              />
            </React.Fragment>
          ))}
        </Layer>
      </Stage>
      {contextMenuVisible && (
        <div
          id="menu"
          style={{
            position: 'absolute',
            top: `${contextMenuPosition.y}px`,
            left: `${contextMenuPosition.x}px`,
            backgroundColor: 'white',
            boxShadow: '0 0 5px grey',
            borderRadius: '3px',
            display: 'block',
            zIndex: 1000
          }}
          className='p-2'
        >
          <button
            onClick={handleChange}
            className="block w-full text-left px-2 py-1 hover:bg-gray-200"
          >
            Pulse
          </button>
          <button
            onClick={handleRemove}
            className="block w-full text-left px-2 py-1 hover:bg-gray-200"
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
}

export default TableScroll;
