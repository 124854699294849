import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';

//api
import { ModuloFacture_OrderFast } from '../../Services/ModuloFacture_autoconta_restaurant/OrderFast/ModuloFacture_OrderFast';
import { ModuleFacture_Kitchen } from '../../Services/ModuloFacture_autoconta_restaurant/Kitchen/ModuleFacture_Kitchen';

//componmentes
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar'

//icons
import TableBarIcon from '@mui/icons-material/TableBar';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

function FacturacionRestaurant() {

   //params
   const { SucursalId } = useParams(); // Captura el parámetro de la URL

   const [itemCount, setItemCount] = useState(null);

   useEffect(() => {
     let isMounted = true; // To check if component is still mounted
 
     const fetchOrders = async () => {
       try {
         console.log('Fetching orders...');
         const response = await ModuleFacture_Kitchen.getOrdenesPendientesBySucursal(SucursalId);
         console.log('Response received:', response);
 
         if (isMounted) {
           // Check the structure of response
           console.log('Response data:', response.data);
 
           // Count the number of entries in the response data object
           const newItemCount = Object.keys(response.data).length;
           console.log('New item count:', newItemCount);
 
           setItemCount(newItemCount);
         }
       } catch (error) {
         console.error('Error fetching orders:', error);
       }
     };
 
     // Fetch data immediately on mount
     fetchOrders();
 
     // Set up interval to fetch data every 5 seconds
     const intervalId = setInterval(fetchOrders, 5000);
 
     // Clean up interval on component unmount
     return () => {
       isMounted = false;
       clearInterval(intervalId);
     };
   }, [SucursalId]);
 

  return (
    <>
    <Navbar_sidebar>
        <div className='container'>
         
        <Typography 
            variant="h1" 
            sx={{ 
              fontFamily: 'Poppins', 
              fontWeight: 500, 
              fontSize: '1.3rem',
              lineHeight: '1.2', 
              marginBottom: '0.4rem',
              color: 'black' 
            }}
          >
            Mode
          </Typography>

         <hr className="border-gray-300  mx-auto" />

            <Stack spacing={5} direction="row" className='mt-5'>
                
            <Box
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
                gap: 1,
            }}
            >  
        
            {/* Mesas Button 
            <Card variant="outlined">   
            <a href='/Facturacion/Table'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
            <TableBarIcon className="h-12 w-12" />
            </Typography>
            <Typography className="mt-2" 
              sx={{ 
                fontFamily: 'Roboto', 
              }}
            >Mesas</Typography>
            </CardContent> 
            </a>
            </Card>   */}

            {/* Orden rapida Button */}
            <Card variant="outlined">   
            <a  href={`/Facturacion/OrderFast/${SucursalId}`}>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
            <AddShoppingCartIcon className="h-12 w-12" />
            </Typography>
            <Typography className="mt-2"  
            sx={{ 
                fontFamily: 'Roboto', 
              }}>Orden rapida</Typography>
            </CardContent> 
            </a>
            </Card>  

            {/* Orden pendientes Button */}
            <Card variant="outlined">   
                <a href={`/Facturacion/PendingOrder/${SucursalId}`} >
                <CardContent className="flex flex-col items-center justify-center">
                <Typography level="title-md" className="flex items-center justify-center">
                <PendingActionsIcon className="h-12 w-12" />
                </Typography>
                <Typography className="mt-2"  
                  sx={{ 
                    fontFamily: 'Roboto', 
                  }}
                  >Ordenes pendientes</Typography>
                </CardContent> 
                </a>
                </Card>  

            {/* Cocina Button */}
            <Card variant="outlined">   
                <a href={`/Facturacion/kitchen/${SucursalId}`}>
                <CardContent className="flex flex-col items-center justify-center">
                <Typography level="title-md" className="flex items-center justify-center">
                <Badge badgeContent={itemCount} color="error" className=''>
                <SoupKitchenIcon className="h-12 w-12" />
                </Badge>
                </Typography>
                <Typography className="mt-2"
                  sx={{ 
                    fontFamily: 'Roboto', 
                  }}
                  >Cocina</Typography>
                </CardContent> 
                </a>
                </Card>  


         </Box>
        </Stack>
      

        {/* Manager */}

        <div className="mt-5">
        <Typography 
            variant="h1" 
            sx={{ 
              fontFamily: 'Poppins', 
              fontWeight: 500, 
              fontSize: '1.3rem',
              lineHeight: '1.2', 
              marginBottom: '0.4rem',
              color: 'black' 
            }}
          >
           Actividad Manager
          </Typography>

         <hr className="border-gray-300  mx-auto" />

         <Stack spacing={5} direction="row" className='mt-5'>
            
         <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gap: 1,
      }}
    >  
    
            {/* Dashboard Button */}
            <Card variant="outlined">   
            <a href={`/Facturacion/Dashboard/${SucursalId}`}>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2"
              sx={{ 
                fontFamily: 'Roboto', 
              }}
            >Dashboard</Typography>
            </CardContent> 
            </a>
            </Card>   

              {/* Set up Button */}
              <Card variant="outlined">   
            <a href={`/Facturacion/Settings/${SucursalId}`}>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2"
              sx={{ 
                fontFamily: 'Roboto', 
              }}
            >Set up</Typography>
            </CardContent> 
            </a>
            </Card>  

              {/* Open day Button */}
              <Card variant="outlined">   
            <a href='/Facturacion/TurnEmployee'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2"
              sx={{ 
                fontFamily: 'Roboto', 
              }}
            >Turnos empleados</Typography>
            </CardContent> 
            </a>
            </Card>  

              {/* Gastos Button */}
              <Card variant="outlined">   
            <a href={`/Facturacion/Bill/${SucursalId}`}>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2"
              sx={{ 
                fontFamily: 'Roboto', 
              }}
            >Gastos</Typography>
            </CardContent> 
            </a>
            </Card>  

            {/* Cerrar dia Button */}
            <Card variant="outlined">   
            <a  href={`/Facturacion/Close_Day/${SucursalId}`}>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2" 
              sx={{ 
                fontFamily: 'Roboto', 
              }}
            >Close Day</Typography>
            </CardContent> 
            </a>
            </Card> 

                {/* Facturas */}
                <Card variant="outlined">   
            <a href='/Facturacion/Invoices'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2" 
              sx={{ 
                fontFamily: 'Roboto', 
              }}
            >Facturas</Typography>
            </CardContent> 
            </a>
            </Card> 
         

         </Box>
        </Stack>
        </div>

           {/* Report */}

           <div className="mt-5">
           <Typography 
            variant="h1" 
            sx={{ 
              fontFamily: 'Poppins', 
              fontWeight: 500, 
              fontSize: '1.3rem',
              marginBottom: '0.4rem',
              color: 'black' 
            }}
            >
            Report
            </Typography>

         <hr className="border-gray-300  mx-auto" />

         <Stack spacing={5} direction="row" className='mt-5'>
            
         <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gap: 1,
      }}
    >  
    
            {/* Dashboard Button */}
            <Card variant="outlined">   
            <a href='/'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2"   sx={{ 
                fontFamily: 'Roboto', 
              }}>Ventas Report</Typography>
            </CardContent> 
            </a>
            </Card>   

              {/* Set up Button */}
              <Card variant="outlined">   
            <a href='/'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2"   sx={{ 
                fontFamily: 'Roboto', 
              }}>Menu report</Typography>
            </CardContent> 
            </a>
            </Card>  

              {/* Open day Button */}
              <Card variant="outlined">   
            <a href='/'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2"   sx={{ 
                fontFamily: 'Roboto', 
              }}> Informe laboral</Typography>
            </CardContent> 
            </a>
            </Card>  

           
        
         

         </Box>
        </Stack>
        </div>

        </div>
    </Navbar_sidebar>
    </>
  )
}

export default FacturacionRestaurant