import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//api
import { ModuloFacture_OrderFast } from '../../../Services/ModuloFacture_autoconta_restaurant/OrderFast/ModuloFacture_OrderFast';

//icons
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';

function ViewProduct({ addItem }) {
    const handleItemClick = (item) => {
        addItem(item);
    };

    //params
    const { SucursalId } = useParams(); // Captura el parámetro de la URL

    const [getCategory, setCategory] = useState([]);
    const itemsPerPage = 4; // Número de items por página
    const [currentPage, setCurrentPage] = useState(0);

    //group
    const [getGroup, setGroup] = useState([]);
    const itemsPerPageGroup = 4; // Número de items por página
    const [currentPageGroup, setCurrentPageGroup] = useState(0);

    //item
    const [getItem, setItem] = useState([]);
    const itemsPerPageItem = 4; // Número de items por página
    const [currentPageItem, setCurrentPageItem] = useState(0);

    useEffect(() => {
        const fetchCategorias = async () => {
            try {
                const categorias = await ModuloFacture_OrderFast.obtenerCategorias(SucursalId);
                const categoriasConId = Object.entries(categorias.data).map(([id, data]) => ({
                    id,
                    ...data,
                }));
                setCategory(categoriasConId);
            } catch (error) {
                console.error('Error al obtener las categorías:', error);
            }
        };

        fetchCategorias();
    }, [SucursalId]);

    //group
    const fetchGroups = async (categoria_id) => {
        try {
            const grupos = await ModuloFacture_OrderFast.getGruposOfCategory(categoria_id);
            setGroup(Object.values(grupos.data));
        } catch (error) {
            console.error('Error al obtener los grupos:', error);
        }
    };

    //item
    const fetchItems = async (item) => {
        try {
            const grupos = await ModuloFacture_OrderFast.getItemsOfGroup(item);
            setItem(Object.values(grupos.data));
        } catch (error) {
            console.error('Error al obtener los items:', error);
        }
    };

    // Handle page changes
    const handlePageChange = (event, value) => {
        setCurrentPage(parseInt(value));
    };

    const handlePageChangeGroup = (event, value) => {
        setCurrentPageGroup(parseInt(value));
    };

    const handlePageChangeItem = (event, value) => {
        setCurrentPageItem(parseInt(value));
    };

    const startIndex = currentPage * itemsPerPage;
    const currentItems = getCategory.slice(startIndex, startIndex + itemsPerPage);

    const startIndexGroup = currentPageGroup * itemsPerPageGroup;
    const currentItemsGroup = getGroup.slice(startIndexGroup, startIndexGroup + itemsPerPageGroup);

    const startIndexItem = currentPageItem * itemsPerPageItem;
    const currentItemsItem = getItem.slice(startIndexItem, startIndexItem + itemsPerPageItem);

    // Create radio buttons for pagination
    const createRadioButtons = (totalItems, itemsPerPage, currentPage, handleChange) => {
        const pageCount = Math.ceil(totalItems / itemsPerPage);
        return (
            pageCount > 1 && (
                <RadioGroup 
                    row 
                    value={currentPage} 
                    onChange={handleChange} 
                >
                    {[...Array(pageCount).keys()].map((page) => (
                        <FormControlLabel 
                            key={page} 
                            value={page} 
                            control={<Radio sx={{ transform: 'scale(0.6)' }} />} // Adjust the size of the radio button
                            sx={{ margin: '-8px' }} // Adjust the margin to reduce space between radio buttons
                            label="" // Remove the label text
                        />
                    ))}
                </RadioGroup>
            )
        );
    };

    return (
        <>
            <Box display="flex" justifyContent="center" mb={3}>
                <input
                    type="text"
                    placeholder="Buscar"
                    className="block w-full py-2.5 text-gray-700 placeholder-gray-400/70 bg-white border border-gray-200 rounded-[40px] pl-11 pr-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 shadow-lg"
                />
            </Box>

            {/* Category */}
            <div className="flex flex-wrap">
                {currentItems.map((item, index) => (
                    <a key={index} onClick={() => fetchGroups(item.id)}>
                        <Card
                            variant="solid"
                            key={index}
                            className="w-40 m-2"
                            style={{ flex: '0 0 calc(20% - 1rem)' }}
                            sx={{ height: '3.8rem', backgroundColor: item.Color }} // Usar item.Color para el color de fondo
                        >
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
                                <Typography sx={{ color: 'black' , fontWeight: 500}}>{item.nombre}</Typography> {/* Color negro para el texto */}
                            </CardContent>
                        </Card>
                    </a>
                ))}
            </div>

            <Box display="flex" justifyContent="center" className="">
                {createRadioButtons(getCategory.length, itemsPerPage, currentPage, handlePageChange)}
            </Box>

            <hr className=''/>

            {/* Groups */}
            <div className="flex flex-wrap">
                {currentItemsGroup.map((item, index) => (
                    <a key={index} onClick={() => fetchItems(item)}>
                        <Card
                            variant="solid"
                            key={index}
                            className="w-40 m-2"
                            style={{ flex: '0 0 calc(20% - 1rem)' }}
                            sx={{ height: '3.8rem', backgroundColor: item.Color }} // Usar item.Color para el color de fondo
                        >
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
                                <Typography sx={{ color: 'black' , fontWeight: 500}}>{item.nombre}</Typography> {/* Color negro para el texto */}
                            </CardContent>
                        </Card>
                    </a>
                ))}
            </div>

            <Box display="flex" justifyContent="center" >
                {createRadioButtons(getGroup.length, itemsPerPageGroup, currentPageGroup, handlePageChangeGroup)}
            </Box>

            <hr />

            {/* Items */}
            <div className="flex flex-wrap">
                {currentItemsItem.map((item, index) => (
                    <a key={index} onClick={() => handleItemClick(item)}>
                        <Card
                            variant="solid"
                            key={index}
                            className="w-40 m-2"
                            style={{ flex: '0 0 calc(20% - 1rem)' }}
                            sx={{ height: '3.8rem', backgroundColor: '#dedcda' }} // Usar item.Color para el color de fondo
                        >
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
                                <Typography sx={{ color: 'black' , fontWeight: 500}}>{item.nombre}</Typography> {/* Color negro para el texto */}
                            </CardContent>
                        </Card>
                    </a>
                ))}
            </div>

            <Box display="flex" justifyContent="center" >
                {createRadioButtons(getItem.length, itemsPerPageItem, currentPageItem, handlePageChangeItem)}
            </Box>
        </>
    );
}

export default ViewProduct;

