import * as React from 'react';
import Datepicker from "react-tailwindcss-datepicker"; 
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
//icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



function BoxInvoicesView() {

    const [value, setValue] = useState({ 
        startDate: null, 
        endDate: null 
        }); 
        
        const handleValueChange = (newValue) => {
        console.log("newValue:", newValue); 
        setValue(newValue); 
        } 

  return (
    <>
           <div className="">
            <Datepicker 
                    value={value} 
                    onChange={handleValueChange} 
                    /> 
        </div>

     <Card sx={{ height: '70vh', position: 'relative', borderRadius: '12px', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)' }}>
          
    <form>
      <div className="container mx-auto">
        {/* Contenedor con scroll */}
        <div className="" style={{ overflowY: 'auto' }}>
        <br/>
        {/* ----- sales & taxes summary-----*/}
         <div className=" bg-gray-200  mb-1">
         <Typography className="p-1"  
                  sx={{ 
                      fontFamily: 'Poppins', 
                      marginLeft:"24px"
                    }}>Gastos resumen</Typography>


         </div>
          <Grid container spacing={2} >
          <Grid item xs={6} >
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  marginLeft:'24px',
                }}

              >Gasto</Typography>
                  <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  marginLeft:'24px',
                }}

              >Taxes</Typography>
          </Grid>
          <Grid item xs={6} >
          <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  textAlign: 'right',
                  marginRight: "10px"
                }}

              >$1.000.000</Typography>
                  <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  textAlign: 'right',
                  marginRight: "10px"
                }}

              >$80.000</Typography>
          </Grid>
          </Grid>
          {/* total */}
          <br/>
          <Grid container spacing={2}>
          <Grid item xs={6} >
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  marginLeft:'24px',
                  fontWeight: 600,
                  marginBottom: "5px"
                }}

              >Total</Typography>
          </Grid>
          <Grid item xs={6} >
          <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  fontWeight: 600,
                  marginBottom: "5px",
                  textAlign: "right",
                  marginRight: "10px"
                }}
              >$180.000
          </Typography>
          </Grid>
          </Grid>

             {/* ----- ventas por metodo de pagos-----*/}
         <div className=" bg-gray-200 mt-2 mb-1">
         <Typography className="p-1"  
                  sx={{ 
                      fontFamily: 'Poppins', 
                      marginLeft:"24px"
                    }}>Gastos por metodo de pago</Typography>


         </div>
          <Grid container spacing={2} >
          <Grid item xs={6} >
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  marginLeft:'24px',
                }}

              >Efectivo</Typography>
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  marginLeft:'24px',
                }}

              >Tarjeta</Typography>
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  marginLeft:'24px',
                }}

              >Transferencia</Typography>
          </Grid>
          <Grid item xs={6} >
             <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  textAlign: 'right',
                  marginRight: "10px"
                }}

              >$1.000.000</Typography>
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  textAlign: 'right',
                  marginRight: "10px"
                }}

              >$80.000</Typography>
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  textAlign: 'right',
                  marginRight: "10px"
                }}

              >$180.000</Typography>
          </Grid>
          </Grid>


        {/* ----- sales cataegory -----*/}
        <div className=" bg-gray-200 mt-2 mb-1">
         <Typography className="p-1"  
                  sx={{ 
                      fontFamily: 'Poppins', 
                      marginLeft:"24px"
                    }}>Gastos Categorias</Typography>


         </div>
          <Grid container spacing={2} >
          <Grid item xs={6} >
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  marginLeft:'24px',
                  fontWeight: 600,
                  marginBottom: "5px"
                }}

              >Categoria</Typography>
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  marginLeft:'24px',
                }}

              >Example</Typography>
          </Grid>
          <Grid item xs={3} >
          <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  fontWeight: 600,
                  marginBottom: "5px"
                }}
              >Cantidad
          </Typography>
          <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                }}

          >Example (2)</Typography>
          </Grid>
          <Grid item xs={3} >
          <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  fontWeight: 600,
                  marginBottom: "5px",
                  textAlign: 'right',
                  marginRight: "10px"
                }}
              >Costo
          </Typography>
          <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  textAlign: 'right',
                  marginRight: "10px"
                }}

              >$1000000</Typography>
          </Grid>
          </Grid>
          {/* total */}
          <br/>
          <Grid container spacing={2}>
          <Grid item xs={6} >
              <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  marginLeft:'24px',
                  fontWeight: 600,
                  marginBottom: "5px"
                }}

              >Total</Typography>
          </Grid>
          <Grid item xs={6} >
          <Typography 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontSize: '12px',
                  fontWeight: 600,
                  marginBottom: "5px",
                  textAlign: "right",
                  marginRight: "10px"
                }}
              >$150.000
          </Typography>
          </Grid>
          </Grid>



        </div>
      </div>


    </form>
  </Card>
    </>
  )
}

export default BoxInvoicesView