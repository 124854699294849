import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';

// Componentes
import CreateProdcuts from '../../../../Components/Common/Factura_restManager/Manager/CreateProdcuts';
import UpdateCategory from '../../../../Components/Common/Factura_restManager/Manager/Background/UpdateCategory';

// API
import { ModuloFacture_OrderFast } from '../../../../Services/ModuloFacture_autoconta_restaurant/OrderFast/ModuloFacture_OrderFast';

function Categories() {
  //params
  const { SucursalId } = useParams(); // Captura el parámetro de la URL

  // Crear vista
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  // Estado para manejar la visibilidad del componente de actualización y el id de la categoría seleccionada
  const [isComponentVisibleUpdate, setIsComponentVisibleUpdate] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const handleCloseBackground = () => {
    setIsComponentVisible(false);
    setIsComponentVisibleUpdate(false);
    setSelectedCategoryId(null); // Restablecer el id seleccionado
  };

  // Obtener todas las categorías
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await ModuloFacture_OrderFast.obtenerCategorias(SucursalId);
        console.log("Datos categorias:", SucursalId);

        if (response.success) {
          const categoriesArray = Object.entries(response.data).map(([id, category]) => ({ id, ...category }));
          setCategories(categoriesArray);
        } else {
          console.error("Error en la respuesta:", response.message);
          setError(response.message);
        }
      } catch (err) {
        console.error("Error al obtener categorías:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const deleteCategory = async (id) => {
    try {
      const response = await ModuloFacture_OrderFast.DeleteCategoriasBySucursal(id);
      console.log("Datos categorias:", response);

      if (response.success) {
        setCategories(categories.filter(category => category.id !== id));
      } else {
        console.error("Error en la respuesta:", response.message);
        setError(response.message);
      }
    } catch (err) {
      console.error("Error al eliminar categoría:", err);
      setError(err);
    }
  };

  const updateCategory = (id) => {
    console.log("Actualizar categoría con ID:", id);
    setSelectedCategoryId(id);
    setIsComponentVisibleUpdate(true);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 230 },
    { field: 'nombre', headerName: 'Nombre', width: 230 },
    { field: 'descripcion', headerName: 'Descripción', width: 300 },
    {
      field: 'action',
      headerName: 'Action',
      width: 250,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => deleteCategory(params.id)}
            style={{ marginRight: 8 }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => updateCategory(params.id)}
          >
            Update
          </Button>
        </>
      ),
    },
    // Agrega más columnas según los datos de tus categorías
  ];

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        <Button variant="contained" onClick={() => setIsComponentVisible(!isComponentVisible)}>Crear</Button>
      </div>

      {/* Tabla de categorías */}
      <div style={{ height: 400, width: '100%' }}>
        {loading ? (
          <p>Cargando...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <DataGrid
            rows={categories}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10]}
            checkboxSelection
          />
        )}
      </div>

      {isComponentVisible && <CreateProdcuts onClose={handleCloseBackground} />}
      {isComponentVisibleUpdate && <UpdateCategory categoryId={selectedCategoryId} onClose={handleCloseBackground} />}
    </>
  );
}

export default Categories;
