import React, { useState } from 'react';
import DatepickerComponent from './Chart/DatepickerComponent'; // Verifica que la ruta sea correcta
import CircleLateral from './Chart/CircleLateral';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';

//compoets
import CardAdminMain from '../../Cards/CardAdminMain';

//chart
import PieChart from '../../Chart/PieChart';
import BarChart from '../../Chart/BarChart';

//api
import { DashboardMain } from '../../../../Services/ModuloFacture_autoconta_restaurant/Dashboard/DashboardMain';

// Función para calcular fechas según el atajo
const getShortcutDates = (shortcut) => {
  const now = new Date();
  let startDate;
  let endDate;

  switch (shortcut) {
    case 'last3Days':
      startDate = new Date(now);
      startDate.setDate(now.getDate() - 3);
      endDate = new Date(now);
      break;
    case 'yesterday':
      startDate = new Date(now);
      startDate.setDate(now.getDate() - 1);
      endDate = new Date(now);
      endDate.setDate(now.getDate() - 1);
      break;
    case 'customToday':
      startDate = endDate = new Date(now);
      break;
    case 'next8Days':
      startDate = new Date(now);
      endDate = new Date(now);
      endDate.setDate(now.getDate() + 8);
      break;
    case 'thisMonth':
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      break;
    case 'lastMonth':
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      endDate = new Date(now.getFullYear(), now.getMonth(), 0);
      break;
    default:
      startDate = null;
      endDate = null;
  }

  return { startDate, endDate };
};

function ChartFacture() {

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const [dataBar, setDataBar] = useState([]);
  const [labelsBar, setLabelsBar] = useState([]);

    //params
    const { SucursalId } = useParams(); // Captura el parámetro de la URL
  

    const handleValueChange = async (newValue) => {
      console.log("newValue:", newValue);
      try {
          const response = await DashboardMain.getVentasBySucursalRange(newValue.startDate, newValue.endDate, SucursalId);
          console.log("data buscada:", response);
          
          const formattedData = formatDataForChart(response);
          
          setDataBar(formattedData.data);
          setLabelsBar(formattedData.labels);
          setValue(newValue);
      } catch (error) {
          console.error("Error al obtener datos:", error);
      }
  };
  

   // Función para formatear los datos para el gráfico
   const formatDataForChart = (data) => {
    const labels = Object.keys(data); // Las fechas como etiquetas
    const values = Object.values(data); // Los valores correspondientes
    return { labels, data: values };
};

  // Configuración de atajos
  const shortcutConfigs = {
    shortcuts: {
      last3Days: {
        text: "Last 3 days",
        action: () => {
          const { startDate, endDate } = getShortcutDates('last3Days');
          setValue({ startDate, endDate });
        },
      },
      yesterday: {
        text: "Yesterday",
        action: () => {
          const { startDate, endDate } = getShortcutDates('yesterday');
          setValue({ startDate, endDate });
        },
      },
      customToday: {
        text: "Custom Today",
        action: () => {
          const { startDate, endDate } = getShortcutDates('customToday');
          setValue({ startDate, endDate });
        },
      },
      next8Days: {
        text: "Next 8 days",
        action: () => {
          const { startDate, endDate } = getShortcutDates('next8Days');
          setValue({ startDate, endDate });
        },
      },
      thisMonth: {
        text: "Este mes",
        action: () => {
          const { startDate, endDate } = getShortcutDates('thisMonth');
          setValue({ startDate, endDate });
        },
      },
      lastMonth: {
        text: "Último mes",
        action: () => {
          const { startDate, endDate } = getShortcutDates('lastMonth');
          setValue({ startDate, endDate });
        },
      },
    },
  };

  const data = [10, 20, 30, 40, 50, 60];
  const labels = ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'];

  return (
    <>

    {/* title & select input */}
    <div className="flex mt-5">
    <Typography 
      variant="h4" 
      sx={{ 
        fontFamily: 'Poppins', 
        fontSize: '24px', // Más grande
        fontWeight: 'bold', // Negrita
        marginBottom: '16px', // Espacio entre el título y el Datepicker
        marginRight: '25px',
        marginLeft: '15px'

      }}
    >
      Dashboard
    </Typography>
    <DatepickerComponent
      value={value}
        onChange={handleValueChange}
        showShortcuts={true}
        showFooter={true}
        configs={shortcutConfigs}
        className="w-60 border border-black mb-4"
    />
    </div>

    <div >
      <Grid container spacing={2} mb={5}>
        {/* Primera columnan */}
        <Grid item xs={12} md={6}>

        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
        <CardAdminMain
          number="2,340"
          title="Ventas Totales"
          percentage="14.6%"
          isPositive={true}
        />
        <CardAdminMain
          number="5,355"
          title="Comidas Más Vendidas"
          percentage="32.9%"
          isPositive={true}
        />
        <CardAdminMain
          number="385"
          title=" Valor Promedio de la Orden"
          percentage="-2.7%"
          isPositive={false}
        />
        <CardAdminMain
          number="5,355"
          title="Visitors this week"
          percentage="32.9%"
          isPositive={true}
        />
      </div>  
        {/*  */}
        <Paper style={{ padding: 20, marginTop: '20px'}}>
        <BarChart data={dataBar} labels={labelsBar} />
        </Paper>

        <Typography 
        sx={{
            fontFamily: 'Poppins',
            fontSize: '12px',
            marginTop: '18px',
            fontWeight: 'bold'
          }}
        >CREADO BY DIKEAN</Typography>
        </Grid>


        {/* Segunda columna */}
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: 20,}}>
           <PieChart data={data} labels={labels} />
          </Paper>

          <Paper style={{ padding: 20, marginTop: '10px'}}>
           <PieChart data={data} labels={labels} />
          </Paper>
        </Grid>
      </Grid>
    </div>


    </>
  );
}

export default ChartFacture;
