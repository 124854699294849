import React, { useState, useEffect } from 'react';
import Card from '@mui/joy/Card';
import { Link } from 'react-router-dom';
import AspectRatio from '@mui/joy/AspectRatio';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';

//img
import Bussness from '../../Assets/Img/Company.jpg'

//components
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar'
import BackgroundCreateSucursales from '../../Components/Common/Factura_restManager/Manager/BackgroundCreateSucursales';

//icon
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';

//api
import { ModuleFacture } from '../../Services/ModuloFacture_autoconta_restaurant/ModuleFacture';

function FacturacionHome() {
  
  const [getSucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //cretae view
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const handleCloseBackground = () => {
    setIsComponentVisible(false);
  };

  useEffect(() => {
    const fetchSucursales = async () => {
      try {
        const response = await ModuleFacture.getSucusalesByUser();
        console.log("Datos de sucursales:", response);

        if (response.success) {
          // Convertir el objeto en un array y añadir el ID a cada objeto de sucursal
          const sucursalesArray = Object.entries(response.data).map(([id, sucursal]) => ({ id, ...sucursal }));
          setSucursales(sucursalesArray);
        } else {
          console.error("Error en la respuesta:", response.message);
          setError(response.message);
        }
      } catch (err) {
        console.error("Error al obtener sucursales:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSucursales();
  }, []); // Dependencias vacías para ejecutar solo una vez al montar el componente

  return (
    <>
      <Navbar_sidebar>
        <div className="container">
          <div className="flex justify-between items-center mb-5 mt-3">
            <h1 className="text-2xl flex-shrink-0 ml-5">
              <BusinessIcon /> Sucursales
            </h1>
          </div>

          <Grid container spacing={3}>
            {/* Card 1 */}
            <Grid item xs={12} sm={6} md={4} lg={3} onClick={() => setIsComponentVisible(!isComponentVisible)}>
              <Card
                variant="outlined"
                sx={{
                  boxShadow: 3,
                  cursor: 'pointer',
                  height: { xs: '200px', sm: '200px', md: '200px', lg: '265px' },
                  width: '100%',
                  '&:hover': {
                    boxShadow:
                      '0 4px 20px 0 rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.2)',
                  },
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <AddIcon fontSize="large" />
                </CardContent>
              </Card>
            </Grid>

            {/* Sucursales */}
            {getSucursales.map((company) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
                <Link to={`/Facturacion/RestaurantDetail/${company.id}`}>
                  <Card
                    variant="outlined"
                    sx={{
                      boxShadow: 3,
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.2)',
                      },
                    }}
                  >
                    <CardOverflow>
                      <AspectRatio ratio="2">
                        <img src={Bussness} loading="lazy" alt="Bussness" />
                      </AspectRatio>
                    </CardOverflow>
                    <CardContent>
                      <Typography level="title-md">{company.nombre}</Typography>
                      <Typography level="body-sm">{company.propietario_id}</Typography>
                    </CardContent>
                    <CardOverflow variant="soft" sx={{ bgcolor: 'background.level1' }}>
                      <Divider inset="context" />
                      <CardContent orientation="horizontal">
                        <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
                          Ubicacion
                        </Typography>
                        <Divider orientation="vertical" />
                        <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
                          {company.direccion}
                        </Typography>
                      </CardContent>
                    </CardOverflow>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>

        {isComponentVisible && <BackgroundCreateSucursales onClose={handleCloseBackground} />}
      </Navbar_sidebar>
    </>
  );
}

export default FacturacionHome;
