import React from 'react';
import { Grid } from '@mui/material';
import NavbarSidebar from '../../Components/Common/Navbar_sidebar';
import ViewTable from './ViewTable';
import TableScroll from './TableScroll';

function FacturacionViewTable() {

  const backgroundStyle = {
    backgroundColor: '#7e7e83',
    backgroundImage: `
      linear-gradient(to right, #57575e 1px, transparent 1px),
      linear-gradient(to bottom, #57575e 1px, transparent 1px)
    `,
    backgroundSize: '60px 60px',

  };

  return (
    <>
       <NavbarSidebar>
        <Grid style={backgroundStyle} container spacing={2}  >
          <Grid item xs={12} md={4}>
            <ViewTable />
          </Grid>
          <Grid item xs={12} md={8}>
            <TableScroll />
          </Grid>
        </Grid>
      </NavbarSidebar>


    </>
  );
}

export default FacturacionViewTable;




