import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Box, Typography, IconButton  } from '@mui/material';
import { CheckCircle } from '@mui/icons-material'; 
import { useParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';

//icons
import InfoIcon from '@mui/icons-material/Info';

function CloseDayColum2() {

  //params
  const { SucursalId } = useParams(); // Captura el parámetro de la URL


  return (
    <>
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            padding: '10px'
          }}
        >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: '15px' }}>
            <CheckCircle sx={{ color: 'green', fontSize: 30 }} /> {/* Icono verde */}
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <Typography variant="body1"
               sx={{ 
                fontWeight: 'bold',
                fontFamily: 'Poppins'

                }}>
                4 Order Pending
              </Typography>
              <Typography 
               sx={{
                 color: 'text.secondary',
                 fontFamily: 'Poppins'
                 }}>
                Cierra toda las ordenes pendientes
              </Typography>
            </Box>
          </Box>

        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" alignItems="center">
        <Box
          sx={{
            width: 30,
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#808080',
            borderRadius: '4px',
             marginLeft: '15px'
          }}
        >
          <Typography  
          sx={{
            color: 'white',
            fontSize: '12px'
          }}>5</Typography>
        </Box>
        <Typography variant="body1" sx={{ marginLeft: 2 }}>
          Por cerrar
        </Typography>
        </Box>
          {/* Button acordion 1 */}
          <AccordionActions>
          <Box display="flex" gap={2}>
            <Button
              variant="outlined"
              href={`/Facturacion/PendingOrder/${SucursalId}`}
            >
              Cerrar Ordenes
            </Button>
            <Button variant="contained">Siguiente</Button>
          </Box>
          </AccordionActions>

        </AccordionDetails>
      </Accordion>
      {/*  */}
      <Accordion>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            padding: '10px'
          }}
        >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: '15px' }}>
            <CheckCircle sx={{ color: '#808080', fontSize: 30 }} /> {/* Icono verde */}
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <Typography variant="body1"
               sx={{ 
                fontWeight: 'bold',
                fontFamily: 'Poppins'

                }}>
                Declarar dinero
              </Typography>
              <Typography 
               sx={{
                 color: 'text.secondary',
                 fontFamily: 'Poppins'
                 }}>
                Reportar dinero
              </Typography>
            </Box>
          </Box>

        </AccordionSummary>
        <AccordionDetails>
        <TextField
        sx={{ marginLeft: '25px' }}
        id="standard-basic" label="Dinero" variant="standard" />

        <AccordionActions>
          <Box display="flex">
            <Button variant="contained">Siguiente</Button>
          </Box>
          </AccordionActions>
        </AccordionDetails>
      </Accordion>
      {/*  */}
      <Accordion>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            padding: '10px'
          }}
        >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: '15px' }}>
            <CheckCircle sx={{ color: '#808080', fontSize: 30 }} /> {/* Icono verde */}
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <Typography variant="body1"
               sx={{ 
                fontWeight: 'bold',
                fontFamily: 'Poppins'

                }}>
                Cierre de caja
              </Typography>
              <Typography 
               sx={{
                 color: 'text.secondary',
                 fontFamily: 'Poppins'
                 }}>
                Ver cualquier error o gasto que no esta
              </Typography>
            </Box>
          </Box>

        </AccordionSummary>
        <AccordionDetails>

        <Box
      sx={{
        width: '95%',
        backgroundColor: '#1976d2', // Azul de Material-UI
        color: '#fff',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        marginLeft: '30px'
      }}
      
    >
      <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'center' }}>
         Sin ninguna novedad
      </Typography>
 
    </Box>
        <Box sx={{ width: '100%' }}>
          <TextField
            sx={{ width: '60%', marginLeft: '30px' }} // O el porcentaje que desees, por ejemplo, '75%'
            id="standard-basic"
            label="Comentario"
            variant="standard"
          />
        </Box>

        <AccordionActions>
          <Box display="flex">
            <Button variant="contained">Siguiente</Button>
          </Box>
          </AccordionActions>
        </AccordionDetails>
      </Accordion>
       {/*  */}
       <Accordion>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            padding: '10px'
          }}
        >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: '15px' }}>
            <CheckCircle sx={{ color: '#808080', fontSize: 30 }} /> {/* Icono verde */}
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <Typography variant="body1"
               sx={{ 
                fontWeight: 'bold',
                fontFamily: 'Poppins'

                }}>
               Clock out
              </Typography>
              <Typography 
               sx={{
                 color: 'text.secondary',
                 fontFamily: 'Poppins'
                 }}>
                Finalizar el cierre del dia
              </Typography>
            </Box>
          </Box>

        </AccordionSummary>
        <AccordionDetails>
        <AccordionActions>
                  <Box display="flex" gap={2}>
                    <Button
                      variant="outlined"
                      href={`/Facturacion/PendingOrder/${SucursalId}`}
                    >
                      Cerrar Ordenes
                    </Button>
                    <Button variant="contained">Cerrar day</Button>
                  </Box>
          </AccordionActions>
        </AccordionDetails>
      </Accordion>
    </div>
    
    </>
  )
}

export default CloseDayColum2