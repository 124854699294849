import React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

//components
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar';

//icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function MarkedAsPaid() {
  return (
    <>
      <Navbar_sidebar>
        <div className="container flex flex-col items-center">

          <Typography 
           sx={{ 
            fontFamily: 'Poppins', 
            fontWeight: 600, 
            marginTop: "25px",
            fontSize: '1.2rem',
            lineHeight: '1.2', 
            marginBottom: '1rem',
            color: 'black'
          }}
          >Marcado como pagado</Typography>

          {/* Box cliente */}
          <div className="w-full max-w-md mb-5">
              <Typography className="text-left"  
              sx={{ 
                fontFamily: 'Poppins', 
                fontWeight: 400, 
                fontSize: '1rem',
                lineHeight: '1.2', 
                marginBottom: '0.4rem',
              }}>
                Cliente
              </Typography>

            <Paper elevation={3} className="w-full max-w-md p-4 mb-5 mt-2" 
            >
              Contenido del recibo
            </Paper>
          </div>

          {/* Opciones de recibo */}
          <div className="w-full max-w-md mb-5">
            
            <p className="text-left"  
            sx={{ 
              fontFamily: 'Poppins', 
              fontWeight: 400, 
              fontSize: '1rem',
              lineHeight: '1.2', 
              marginBottom: '0.4rem',
            }}
            >Opciones de recibo</p>

            <Paper elevation={3} className="w-full max-w-md p-4 mb-5 mt-2">
              <a href="#" className="flex justify-between items-center p-3 hover:bg-gray-200 transition duration-200">
                <span className='ml-3'>Enviar recibo</span>
                <ArrowForwardIosIcon style={{ fontSize: 14 }} />
              </a>
              <a href="#" className="flex justify-between items-center p-3 hover:bg-gray-200 transition duration-200 mt-2">
                <span className='ml-3'>Enviar por mensaje de texto</span>
                <ArrowForwardIosIcon style={{ fontSize: 14 }}/>
              </a>
            </Paper>
          </div>

          {/* Button */}
          <Button variant="contained" className='mt-5 w-full max-w-md'>
            Listo
          </Button>
        </div>
      </Navbar_sidebar>
    </>
  )
}

export default MarkedAsPaid;
