// api/companys
import Cookies from 'js-cookie';
import axios from 'axios';
import { UrlApi } from './UrlApi';
const ApiUrl = UrlApi; // Url 

export const ModuleFacture = {

    getSucusalesByUser: () => {

            const UserId = Cookies.get('authUserId'); // Obtiene el User ID de auth 0 ${UserId}
        
            return axios.post(`${ApiUrl}/getSucursalesByUser`, { user_id: UserId })
        .then(response => {
            return response.data
        })
        .catch(error => {
            // Manejar el error
            console.error("Error en la solicitud:", error);
        });
        
        
    },

    postCreateSucursales: () => {
        return axios.post(`${ApiUrl}/CreateSucursales`, {
            nombre: "nombre",
            email: "email",
            ubicacion: "ubicacion",
            access_key: "access_key",
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error("Error al obtener los item:", error);
        });
    },

    getEmployeBysucursalId: (sucursal_id) => {
        return axios.post(`${ApiUrl}/getEmpleadosBySucursalId`, { sucursal_id: sucursal_id })
    .then(response => {
        return response.data
    })
    .catch(error => {
        console.error("Error en la solicitud:", error);
    });
    
    
},
}