import React from 'react';
import { Routes } from 'react-router-dom';
import publicRoutes from './PublicRoutes';
import adminRoutes from './Auth/AdminRoutes';
import Factura_restManager from './Module Factura_restManager/Factura_restManager';

const AppRoutes = () => {
  return (
    <Routes>
         {/* Puedes añadir más grupos de rutas aquí */}
      {publicRoutes}
      {adminRoutes}
        {/* Ruta Modulo Facturacion */}
      {Factura_restManager}
    </Routes>
  );
};

export default AppRoutes;
