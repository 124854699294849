import React, { useState } from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import "../../../../Assets/Css/ModalSweetAlert.css";
import { useParams } from 'react-router-dom';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


import { ModuleFacture_Gastos } from '../../../../Services/ModuloFacture_autoconta_restaurant/Gastos/ModuleFacture_Gastos'; 

//componentes
import Background from '../../HomeApp/Background';

//icons
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

function ViewCreateGasto({ onClose }) {

    //params
    const { SucursalId } = useParams();

    const [isJoinOneCompanyVisible, setIsJoinOneCompanyVisible] = useState(false);

    const [formData, setFormData] = useState({
        monto: '',
        descripcion: '',
        fecha: '',
        metodo_pago: '',  // Añadir estado para el método de pago
    });
    

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        ModuleFacture_Gastos.CreateGastos({
            monto: formData.monto,
            descripcion: formData.descripcion,
            fecha: formData.fecha,
            sucursal_id: SucursalId,
            metodo_pago: formData.metodo_pago  // Incluir el método de pago
        })
        .then(response => {
            Swal.fire({
                title: '¡Éxito!',
                text: 'Gasto creado exitosamente',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                    container: 'swal2-popup-custom '
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        })
        .catch(error => {
            Swal.fire({
                title: 'Error',
                text: 'No se pudo crear el gasto',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    container: 'swal2-popup-custom '
                }
            });
            console.error('Error al crear el gasto:', error);
        });
    };
    

  return (
    <>
     <Background onClose={onClose}>
            <Card sx={{ maxWidth: 345, padding: '20px' }}>
                {!isJoinOneCompanyVisible && (
                    <CardContent>
                        <Typography gutterBottom variant="h7" component="div">
                            <div className="flex justify-between items-center">
                                <div>
                                    <LocalAtmIcon /> Crear Gasto
                                </div>
                            </div>
                        </Typography>

                        <form className="mt-3" onSubmit={handleSubmit}>
                        <TextField
                            name="monto"
                            label="Monto"
                            variant="standard"
                            className="mb-5"
                            value={formData.monto}
                            onChange={handleChange}
                        />
                        <TextField
                            name="descripcion"
                            label="Descripción"
                            variant="standard"
                            className="mb-5"
                            value={formData.descripcion}
                            onChange={handleChange}
                        />
                        <TextField
                            name="fecha"
                            label="Fecha"
                            variant="standard"
                            value={formData.fecha}
                            onChange={handleChange}
                        />
                        <FormControl fullWidth variant="standard" className="mb-5">
                            <InputLabel id="metodo-pago-label">Método de Pago</InputLabel>
                            <Select
                                labelId="metodo-pago-label"
                                id="metodo-pago"
                                name="metodo_pago"
                                value={formData.metodo_pago}
                                onChange={handleChange}
                            >
                                <MenuItem value="efectivo">Efectivo</MenuItem>
                                <MenuItem value="transferencia">Transferencia</MenuItem>
                                <MenuItem value="tarjeta">Tarjeta</MenuItem>
                            </Select>
                        </FormControl>
    
    <div className="mt-5">
        <Button type="submit" variant="contained" color="primary">
            Crear Gasto
        </Button>
    </div>
                        </form>

                    </CardContent>
                )}
                <CardActions />
            </Card>
        </Background>
    </>
  )
}

export default ViewCreateGasto