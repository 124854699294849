import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { TextField, Button, Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Components
import ViewCreateGasto from '../../Manager/ViewCreateGasto';

// Icons
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddIcon from '@mui/icons-material/Add';

// API
import { ModuleFacture_Gastos } from '../../../../../Services/ModuloFacture_autoconta_restaurant/Gastos/ModuleFacture_Gastos';

// Define columns according to the data structure
const columns = (handleDelete, handleEdit) => [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'descripcion', headerName: 'Descripción', width: 200 },
  { field: 'fecha', headerName: 'Fecha', width: 130 },
  { field: 'monto', headerName: 'Monto', width: 100, type: 'number' },
  {
    field: 'actions',
    headerName: 'Acciones',
    width: 150,
    sortable: false,
    renderCell: (params) => (
      <>
        <IconButton
          onClick={() => handleEdit(params.id)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => handleDelete(params.id)}
        >
          <DeleteIcon />
        </IconButton>
      </>
    ),
  },
];

function TableInvoices() {

     // ViewCreate & Update
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [isComponentVisibleUpdate, setIsComponentVisibleUpdate] = useState(false);

  const [editGastoId, setEditGastoId] = useState(null);

  const handleCloseBackground = () => {
    setIsComponentVisible(false);
    setIsComponentVisibleUpdate(false);
    setEditGastoId(null);
  };

  const { SucursalId } = useParams();

  const [gastos, setGastos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGastos = async () => {
      try {
        const response = await ModuleFacture_Gastos.GetGastosBySucursal(SucursalId);
        console.log("Datos de GASTOS:", response);

        if (response.success) {
          const gastosArray = Object.entries(response.data).map(([id, gasto]) => ({ id, ...gasto }));
          setGastos(gastosArray);
        } else {
          console.error("Error en la respuesta:", response.message);
          setError(response.message);
        }
      } catch (err) {
        console.error("Error al obtener gastos:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGastos();
  }, [SucursalId]);

    const handleDelete = async (id) => {
      try {
        console.log("ID a eliminar: " + id);
        const response = await ModuleFacture_Gastos.DeleteGastoById(id);
        console.log("Eliminado:", response);
        setGastos(gastos.filter(gasto => gasto.id !== id));
      } catch (error) {
        console.error("Error al eliminar gasto:", error);
      }
    };

  const handleEdit = (id) => {
    setEditGastoId(id);
    setIsComponentVisible(true);
  };

  return (
    <>
             {/*  */}
      <Box sx={{ pl: 2 , py: 0}}>
      <h1 style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '24px' }}>Facturas</h1>
      <p style={{ color: 'gray' }}>Información sobre las facturas</p>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
        className=""
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          <AddIcon />
        </Button>
      </Box>
    </Box>
      {/* Table */}
      <section className="container px-4 mx-auto">
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={gastos} // Use the 'gastos' state here
            columns={columns(handleDelete, handleEdit)} 
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            loading={loading} // Show loading indicator while fetching data
          />
        </div>
      </section>

      {isComponentVisible && <ViewCreateGasto onClose={handleCloseBackground} />}
      {isComponentVisibleUpdate && <ViewCreateGasto onClose={handleCloseBackground} editGastoId={editGastoId} />}

    </>
  )
}

export default TableInvoices