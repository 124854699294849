import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box, Paper, Typography, Pagination } from '@mui/material';
import { useQuery, useQueryClient, QueryClient, QueryClientProvider } from 'react-query';
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar';
import ViewOrderPendingTable from '../../Components/Common/Factura_restManager/ViewOrderPendingTable';
import PaymentsIcon from '@mui/icons-material/Payments';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Loading from '../../Components/Common/Loading';
import { ModuloFacture_OrderFast } from '../../Services/ModuloFacture_autoconta_restaurant/OrderFast/ModuloFacture_OrderFast';

const queryClient = new QueryClient();

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function PendingOrder() {
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const UpdateStatusFinalizar = async(Order_id) => {
    const response = await ModuloFacture_OrderFast.UpdateStatusOrder({
      estado: "Finalizada",
      orden_id: Order_id
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { SucursalId } = useParams();
  const queryClient = useQueryClient();

  const fetchOrders = async () => {
    const response = await ModuloFacture_OrderFast.GetOrdenesPendientesBySucursal(SucursalId);
    return Object.entries(response.data).map(([id, order]) => ({ id, ...order }));
  };

  const fetchOrdersFinalizadas = async () => {
    const response = await ModuloFacture_OrderFast.GetOrdenesFinalizadasBySucursal(SucursalId);
    return Object.entries(response.data).map(([id, order]) => ({ id, ...order }));
  };

  const { data: orders, isLoading, isError } = useQuery(['orders', SucursalId], fetchOrders, {
    refetchInterval: 5000, // Refrescar datos cada 5 segundos
  });

  const { data: finalizadas, isLoading: isLoadingFinalizadas, isError: isErrorFinalizadas } = useQuery(['finalizadas', SucursalId], fetchOrdersFinalizadas, {
    refetchInterval: 5000, // Refrescar datos cada 5 segundos
  });

  const [currentPage, setCurrentPage] = React.useState(1);
  const ordersPerPage = 5;
  const [selectedOrder, setSelectedOrder] = React.useState(null);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders ? orders.slice(indexOfFirstOrder, indexOfLastOrder) : [];
  const currentFinalizadas = finalizadas ? finalizadas.slice(indexOfFirstOrder, indexOfLastOrder) : [];

  if (isLoading || isLoadingFinalizadas) return <Loading />;
  if (isError || isErrorFinalizadas) return <div>Error fetching data</div>;

  return (
    <Navbar_sidebar>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Ordenes Pendientes" {...a11yProps(0)} />
                <Tab label="Finalizadas" {...a11yProps(1)} />
                <Tab label="Historial" {...a11yProps(1)} />
              </Tabs>
            </Box>
            {/* Item 1 */}
            <CustomTabPanel value={value} index={0}>
              <div className="p-2">
                <Paper
                  elevation={3}
                  sx={{
                    background: '#808080',
                    color: 'white',
                    display: { xs: 'none', md: 'block' }, // Oculta en pantallas XS y SM, muestra en MD y arriba
                  }}
                >
                  <Grid container className='mb-3'>
                    <Grid item xs={3} className='px-4 p-2'>ID</Grid>
                    <Grid item xs={2} className='px-4 p-2'>Costo</Grid>
                    <Grid item xs={3} className='p-2'>
                      <Typography sx={{ marginLeft: '15px' }}>Mesa</Typography>
                    </Grid>
                    <Grid item xs={2} className='px-4 p-2'>Estado</Grid>
                    <Grid item xs={2} className='px-4 p-2'>Acciones</Grid>
                  </Grid>
                </Paper>
                {currentOrders.map((order, index) => (
                  <Paper elevation={1} className="px-5 py-3 mb-2" key={index}>
                    <a onClick={() => setSelectedOrder(order)}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography sx={{ marginBottom: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            #{order.id}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <PaymentsIcon /> <span className='ml-2'>${order.precio}</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <Typography>{order.mesa_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <Box
                            sx={{
                              padding: '5px',
                              borderRadius: '10px',
                              backgroundColor: '#FFCE54',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircleIcon sx={{ marginRight: '5px', fontSize: '16px' }} />
                            <Typography variant="body1" sx={{ fontSize: '14px', fontFamily: 'Poppins' }}>
                              {order.estado}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                          <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{ textAlign: 'right', justifyContent: "flex-end" }}
                          >
                            <Typography><MoreVertIcon /></Typography>
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                          >
                            <MenuItem onClick={() => UpdateStatusFinalizar(order.id)}>Finalizar</MenuItem>
                            <MenuItem onClick={handleClose}>Procesando</MenuItem>
                            <MenuItem onClick={handleClose}>Cancelar</MenuItem>
                          </Menu>
                        </Grid>
                      </Grid>
                    </a>
                  </Paper>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                  <Pagination count={Math.ceil(orders.length / ordersPerPage)} color="primary" page={currentPage} onChange={handlePageChange} />
                </Box>
              </div>
            </CustomTabPanel>
            {/* Item 2 */}
            <CustomTabPanel value={value} index={1}>
             <div className="p-2">
                <Paper
                  elevation={3}
                  sx={{
                    background: '#808080',
                    color: 'white',
                    display: { xs: 'none', md: 'block' }, // Oculta en pantallas XS y SM, muestra en MD y arriba
                  }}
                >
                  <Grid container className='mb-3'>
                    <Grid item xs={3} className='px-4 p-2'>ID</Grid>
                    <Grid item xs={2} className='px-4 p-2'>Costo</Grid>
                    <Grid item xs={3} className='p-2'>
                      <Typography sx={{ marginLeft: '15px' }}>Mesa</Typography>
                    </Grid>
                    <Grid item xs={2} className='px-4 p-2'>Estado</Grid>
                    <Grid item xs={2} className='px-4 p-2'>Acciones</Grid>
                  </Grid>
                </Paper>
                {currentFinalizadas.map((order, index) => (
                  <Paper elevation={1} className="px-5 py-3 mb-2" key={index}>
                    <a onClick={() => setSelectedOrder(order)}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography sx={{ marginBottom: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            #{order.id}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <PaymentsIcon /> <span className='ml-2'>${order.precio}</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <Typography>{order.mesa_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <Box
                            sx={{
                              padding: '5px',
                              borderRadius: '10px',
                              backgroundColor: '#58d68d',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircleIcon sx={{ marginRight: '5px', fontSize: '16px' }} />
                            <Typography variant="body1" sx={{ fontSize: '14px', fontFamily: 'Poppins' }}>
                              {order.estado}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                          <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{ textAlign: 'right', justifyContent: "flex-end" }}
                          >
                            <Typography><MoreVertIcon /></Typography>
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                          >
                            <MenuItem onClick={handleClose}>Finalizar</MenuItem>
                            <MenuItem onClick={handleClose}>Procesando</MenuItem>
                            <MenuItem onClick={handleClose}>Cancelar</MenuItem>
                          </Menu>
                        </Grid>
                      </Grid>
                    </a>
                  </Paper>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                  <Pagination count={Math.ceil(finalizadas.length / ordersPerPage)} color="primary" page={currentPage} onChange={handlePageChange} />
                </Box>
              </div>
            </CustomTabPanel>

            {/* Historial */}
            <CustomTabPanel value={value} index={2}>
          <div className="p-2">
                <Paper
                  elevation={3}
                  sx={{
                    background: '#808080',
                    color: 'white',
                    display: { xs: 'none', md: 'block' }, // Oculta en pantallas XS y SM, muestra en MD y arriba
                  }}
                >
                  <Grid container className='mb-3'>
                    <Grid item xs={3} className='px-4 p-2'>ID</Grid>
                    <Grid item xs={2} className='px-4 p-2'>Costo</Grid>
                    <Grid item xs={3} className='p-2'>
                      <Typography sx={{ marginLeft: '15px' }}>Mesa</Typography>
                    </Grid>
                    <Grid item xs={2} className='px-4 p-2'>Estado</Grid>
                    <Grid item xs={2} className='px-4 p-2'>Acciones</Grid>
                  </Grid>
                </Paper>
                {currentFinalizadas.map((order, index) => (
                  <Paper elevation={1} className="px-5 py-3 mb-2" key={index}>
                    <a onClick={() => setSelectedOrder(order)}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography sx={{ marginBottom: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            #{order.id}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <PaymentsIcon /> <span className='ml-2'>${order.precio}</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <Typography>{order.mesa_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <Box
                            sx={{
                              padding: '5px',
                              borderRadius: '10px',
                              backgroundColor: '#FFCE54',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircleIcon sx={{ marginRight: '5px', fontSize: '16px' }} />
                            <Typography variant="body1" sx={{ fontSize: '14px', fontFamily: 'Poppins' }}>
                              {order.estado}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                          <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{ textAlign: 'right', justifyContent: "flex-end" }}
                          >
                            <Typography><MoreVertIcon /></Typography>
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                          >
                            <MenuItem onClick={handleClose}>Finalizar</MenuItem>
                            <MenuItem onClick={handleClose}>Procesando</MenuItem>
                            <MenuItem onClick={handleClose}>Cancelar</MenuItem>
                          </Menu>
                        </Grid>
                      </Grid>
                    </a>
                  </Paper>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                  <Pagination count={Math.ceil(finalizadas.length / ordersPerPage)} color="primary" page={currentPage} onChange={handlePageChange} />
                </Box>
              </div>
            </CustomTabPanel>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <ViewOrderPendingTable order={selectedOrder} />
        </Grid>
      </Grid>
    </Navbar_sidebar>
  );
}

export default function WrappedPendingOrder() {
  return (
    <QueryClientProvider client={queryClient}>
      <PendingOrder />
    </QueryClientProvider>
  );
}
